// @ts-nocheck
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../utils/currencyHelper';
import { getItemDepositAmount, getItemTotal } from '../../../../utils/booking';
import * as PropTypes from 'prop-types';
import './BookingTotalItem.css';

/**
 * Functional React component for rendering a custom booking total item.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Object} [props.data] - The data for booking total item.
 * @param {string} [props.id] - The ID for the booking total item.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the booking total item component.
 */

const BookingTotalItem = ({ data, id, ...rest }) => {
  const {
    title,
    hideTotalBreakdown,
    selectedQuantity,
    priceperperson,
    hideDeposit
  } = data;
  const { t } = useTranslation(['trip', 'common']);
  const depositAmount = getItemDepositAmount(data);
  const total = getItemTotal(data);

  return (
    <Box
      className='booking-total__item'
      data-testid='booking-total-item'
      id={id}
      {...rest}
    >
      <div className='booking-total__item-column'>
        <span className='text-md-medium booking-total__item-column__title'>
          {title}
        </span>

        {!hideTotalBreakdown && (
          <span className='text-sm-medium text-muted--primary'>
            {selectedQuantity} x {formatCurrency(priceperperson)}
          </span>
        )}
      </div>
      <div className='booking-total__item-column'>
        <span className='h3-semibold'>{formatCurrency(total)}</span>
        {!hideDeposit && depositAmount > 0 && (
          <div className='text-sm-medium booking-total__item-column__deposit'>
            <span className='text-muted--primary'>{t('deposit')}:</span>
            <span className='price-per-person__text'>
              {formatCurrency(depositAmount)}
            </span>
          </div>
        )}
      </div>
    </Box>
  );
};

BookingTotalItem.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    depositamount: PropTypes.number,
    depositduedate: PropTypes.string,
    lastbookdate: PropTypes.string,
    maxavailable: PropTypes.number,
    occupancy: PropTypes.number,
    priceperperson: PropTypes.number,
    tripplanid: PropTypes.number,
    title: PropTypes.string,
    selectedQuantity: PropTypes.number,
    hideTotalBreakdown: PropTypes.bool,
    hideDeposit: PropTypes.bool
  }),
  id: PropTypes.string.isRequired
};

export default BookingTotalItem;
