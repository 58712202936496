import request from '../request';

export const getTenant = () => {
  return request.get('tenant').then((res) => res);
};

export const getSettingsByCode = (code = '') => {
  return request.get(`tenant/settings/${code}`).then((res) => res);
};
export const getTenantSettings = (settingsCode = '', data = {}) =>
  request.get(`/tenant/settings/${settingsCode}`, data).then((res) => res);

export const setTenantSettings = (settingCode = '', data = {}) =>
  request.post(`tenant/settings/${settingCode}`, data).then((res) => res);

export const getPassengerFields = () => {
  return request.get('tenant/passengerfields').then((res) => res);
};

export const createPassengerField = (data = {}) =>
  request.post('tenant/passengerfields', data).then((res) => res);

export const updatePassengerField = (data = {}, passengerfieldid = '') =>
  request
    .put(`tenant/passengerfields/${passengerfieldid}`, data)
    .then((res) => res);

export const deletePassengerField = (passengerfieldid = '') =>
  request
    .delete(`tenant/passengerfields/${passengerfieldid}`)
    .then((res) => res);

export const updatePassengerFieldSequence = (
  passengerfieldid = '',
  data = {}
) =>
  request
    .put(`tenant/passengerfields/${passengerfieldid}/sequence`, data)
    .then((res) => res);

export const updatePassengerFieldStatus = (passengerfieldid = '', data = {}) =>
  request
    .put(`tenant/passengerfields/${passengerfieldid}/status`, data)
    .then((res) => res);
