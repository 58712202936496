// @ts-nocheck
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

/**
 * Render the booking passengers grid skeleton
 *
 * @param {string} id - Component ID
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 * @return {JSX.Element} The the passengers field skeleton component
 */
const PassengersSkeleton = ({ id, ...rest }) => {
  return (
    <Box
      id={id}
      data-testid='booking-passengers-skeleton'
      style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      {...rest}
    >
      <Box
        style={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'flex-end',
          padding: '16px',
          paddingBottom: '0px'
        }}
      >
        <Skeleton
          variant='rectangular'
          width={'300px'}
          height={42}
          sx={{
            marginBottom: '24px',
            borderRadius: '8px'
          }}
        />
        <Skeleton
          variant='rectangular'
          width={'176px'}
          height={42}
          sx={{
            marginBottom: '24px',
            borderRadius: '8px'
          }}
        />
      </Box>

      <Skeleton
        variant='rectangular'
        width={'100%'}
        height={56}
        sx={{ borderBottom: '1px solid #EAECF0', backgroundColor: '#f5f5f5' }}
      />
      {Array.from(new Array(6)).map((_, index) => (
        <Skeleton
          key={index}
          variant='rectangular'
          width={'100%'}
          height={52}
          sx={{ borderBottom: '1px solid #EAECF0', backgroundColor: '#f5f5f5' }}
        />
      ))}
    </Box>
  );
};

PassengersSkeleton.propTypes = {
  id: PropTypes.string
};

export default PassengersSkeleton;
