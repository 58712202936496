// @ts-nocheck
import { useEffect, useState } from 'react';
import { Slide, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { XGButton, XGInput } from '@xg-cl/xg-cl';
import { X } from '@phosphor-icons/react';
import { t } from 'i18next';
import * as PropTypes from 'prop-types';
import './LibraryNewItemSlide.css';
import ImagePicker from '../../../Upload/ImagePicker/ImagePicker';
import Editor from '../../../Editor/Editor';
import {
  saveContentToLibrary,
  updateContent
} from '../../../../services/library/content';
import { ErrorToast, SuccessToast } from '../../../../utils/alerts';

/**
 * Functional React component for rendering a custom trip detailed day content.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {boolean} [props.openSlide] - Open slide state.
 * @param {function} [props.handleSlideClose] - Function to handle open slide state.
 * @param {Array} [props.libraryContentTypes] - Function to handle open slide state.
 * @param {Array} [props.handleOnItemAdded] - Updates the content array after item was added.
 * @param {Array} [props.initialContent] - Initial library content to append to new item added
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip detailed day.
 */

const LibraryNewItemSlide = ({
  openSlide,
  handleSlideClose,
  libraryContentTypes,
  handleOnItemAdded,
  initialContent,
  idSelected,
  handleDelete
}) => {
  const [content, setContent] = useState({});
  const [shouldShowNote, setShouldShowNote] = useState(false);
  const [editorDescription, setEditorDescription] = useState('');
  const [editorNote, setEditorNote] = useState('');
  const [internalNote, setInternalNote] = useState('');
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [description, setDescription] = useState('');

  const onChangeOption = (event) => {
    const { value } = event.target;

    const foundType = libraryContentTypes?.find(
      (item) => item.contenttypeid == value
    );

    setShouldShowNote(foundType?.code === 'day');
    setContent((prevState) => ({
      ...prevState,
      contenttypeid: value
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContent((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const setMarkdown = (value) => {
    setEditorDescription(value);
  };

  const setNoteMarkdown = (value) => {
    setEditorNote(value);
  };

  const insertImage = async (newImageUrl) => {
    setContent((prevState) => ({
      ...prevState,
      images: [{ imageurl: newImageUrl, seq: 1 }]
    }));
  };

  const handleClose = () => {
    setContent({
      contenttypeid: 0,
      title: '',
      description: '',
      note: '',
      images: []
    });
    handleSlideClose();
  };

  const isFormValid = () => {
    if (content.contenttypeid === 0) return false;
    if (content.title.length === 0) return false;
    return true;
  };

  const handleSave = async () => {
    if (!isFormValid()) {
      ErrorToast(t('library:errorSavingContent'));
      return;
    }

    try {
      setDisableSaveBtn(true);
      let result;
      idSelected > 0
        ? (result = await updateContent(content.contentid, {
            ...content,
            description: editorDescription,
            note: shouldShowNote ? editorNote : null
          }))
        : (result = await saveContentToLibrary({
            ...content,
            description: editorDescription,
            note: shouldShowNote ? editorNote : null
          }));
      if (result.error || !result.data) {
        ErrorToast(t('library:errorSavingContent'));
      } else {
        const contentimage = result.data.images;
        const newResult = { ...result.data, contentimage };

        if (idSelected > 0) {
          const arrayCopy = [...initialContent].map((el) => {
            if (el.contentid === newResult.contentid) {
              return { ...el, ...newResult };
            } else {
              return el;
            }
          });

          handleOnItemAdded(arrayCopy);
        } else {
          handleOnItemAdded([newResult, ...initialContent]);
        }

        SuccessToast(t('library:itemSaved'));
      }
      handleClose();
    } catch (err) {
      ErrorToast(t('library:errorSavingContent'));
    } finally {
      setDisableSaveBtn(false);
      setMarkdown('');
    }
  };

  const handleDeleteButton = async () => {
    handleDelete(idSelected);
  };

  useEffect(() => {
    const calculatedContent =
      idSelected > 0
        ? initialContent.find((item) => item.contentid === idSelected)
        : {
            contenttypeid: 0,
            title: '',
            description: '',
            note: '',
            images: []
          };

    setContent(calculatedContent);
    setMarkdown(calculatedContent?.description || '');
    setDescription(calculatedContent?.description || '');
    setInternalNote(calculatedContent?.note || '');
    setEditorNote(calculatedContent?.note || '');
  }, [idSelected, initialContent]);

  useEffect(() => {
    const type = libraryContentTypes?.find(
      (item) => item?.contenttypeid == content?.contenttypeid
    );

    setDisableSaveBtn(!content?.contenttypeid || content?.contenttypeid === 0);
    setShouldShowNote(type?.code === 'day');
  }, [content, libraryContentTypes]);

  return (
    <Slide
      className='library_new_item_slide'
      direction='left'
      in={openSlide}
      mountOnEnter
      unmountOnExit
    >
      <Box id='content__slide__container'>
        <div className='library_new_item_slide_header'>
          <h2 className='h2-semibold'>
            {idSelected !== 0 ? t('library:editContent') : t('library:newItem')}
          </h2>
          <X size={35} onClick={handleClose} className='library-slide-close' />
        </div>
        <div className='library_new_item_slide_body'>
          <Box className='library_new_item_radioGroup_container'>
            <RadioGroup
              name='use-radio-group'
              value={content?.contenttypeid ? content.contenttypeid : 0}
              onChange={onChangeOption}
            >
              {libraryContentTypes &&
                libraryContentTypes.length > 0 &&
                libraryContentTypes.map((contentType) => {
                  return (
                    <FormControlLabel
                      key={contentType.contenttypeid}
                      value={contentType.contenttypeid}
                      label={contentType.title}
                      control={<Radio />}
                    />
                  );
                })}
            </RadioGroup>
          </Box>

          <ImagePicker
            callback={insertImage}
            id={`image-picker-component`}
            handleUploading={() => {}}
            url={
              content?.contentimage &&
              Array.isArray(content.contentimage) &&
              content.contentimage.length > 0
                ? content.contentimage[content.contentimage.length - 1].imageurl
                : null
            }
          />
          <Box className='library_new_item_input_container'>
            <p>{t('library:title')}</p>
            <XGInput
              name='title'
              placeholder={t('library:enterTitle')}
              id='library-new-content-title'
              type='text'
              value={content?.title ? content.title : ''}
              onChange={handleChange}
              inputProps={{
                maxLength: 250
              }}
            />
          </Box>
          <Box className='library_new_item_editor_container'>
            <Editor
              editable={true}
              content={description}
              id='library-new-content-description'
              setDescription={() => {}}
              setMarkdown={setMarkdown}
              placeholder={t('library:enterDecription')}
              label={t('trip:description')}
              className='library_new_item_editor'
            />
          </Box>
          {shouldShowNote && (
            <Box className='library_new_item_input_container'>
              <Editor
                editable={true}
                content={internalNote}
                id='library-new-content-note'
                setDescription={() => {}}
                setMarkdown={setNoteMarkdown}
                placeholder={t('trip:enterInternalNotes')}
                label={t('trip:internalNotes')}
                className='library_new_item_editor'
              />
            </Box>
          )}

          <Box className='library_new_item_footer'>
            <XGButton
              id='new-itemcancel-button'
              data-testid='new-item-cancel-button'
              size='large'
              variant='contained'
              type='secondary'
              text={t('common:cancel')}
              onClick={handleClose}
            />
            {idSelected ? (
              <XGButton
                id='new-itemcancel-button'
                data-testid='new-item-cancel-button'
                size='large'
                variant='contained'
                type='error'
                text={t('common:delete')}
                onClick={handleDeleteButton}
              />
            ) : (
              ''
            )}
            <XGButton
              id='new-item-save-button'
              data-testid='new-item-save-button'
              size='large'
              variant='contained'
              type='primary'
              text={t('common:save')}
              disabled={disableSaveBtn}
              onClick={handleSave}
            />
          </Box>
        </div>
      </Box>
    </Slide>
  );
};

LibraryNewItemSlide.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  handleSlideClose: PropTypes.func,
  libraryContentTypes: PropTypes.array.isRequired,
  handleOnItemAdded: PropTypes.func.isRequired,
  initialContent: PropTypes.array.isRequired,
  idSelected: PropTypes.number,
  handleDelete: PropTypes.func
};

export default LibraryNewItemSlide;
