// @ts-nocheck
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { XGButton } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Checkbox, FormControl, ListItemText, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { downloadExcel } from '../../../../utils/convertToExcel';
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';
import { ErrorToast } from '../../../../utils/alerts';
import { generatePassengersPdf } from '../../../../services/booking/booking';
import './PassengersHeader.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom passengers header.
 *
 * @namespace Components
 *
 * @param {Object} options - The passenger settings object
 * @param {Function} onUpdateColumns - Function that updates the rows and columns of the grid
 *
 * @returns {JSX.Element} React element representing the passengers header.
 */

const PassengersHeader = ({
  options,
  onUpdateColumns,
  rows,
  columns,
  gridApiRef
}) => {
  const { t } = useTranslation(['passengers', 'common']);
  const trip = useSelector((state) => state.trip);
  const tripname = trip.tripname;
  const date = formatDate(utcToZonedTime(new Date(), 'UTC'), 'yyy MMM dd', {
    timeZone: 'UTC'
  });
  const [pdfIsLoading, setPdfIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState(() => {
    const filteredColumns = localStorage.getItem('filteredColumns');
    if (filteredColumns) {
      return JSON.parse(filteredColumns);
    } else {
      return options
        .filter((field) => field.isoptional || field.isrequired)
        .map((field) => field.field);
    }
  });
  const [selectAllChecked, setSelectAllChecked] = useState(() => {
    const filteredColumnsStr = localStorage.getItem('filteredColumns');
    const filteredColumns = JSON.parse(filteredColumnsStr) ?? [];

    const allOptions = options
      .filter((field) => field.isoptional || field.isrequired)
      .map((field) => field.field);

    const setOptions = new Set(allOptions);
    const setColumns = new Set(filteredColumns);
    setOptions.size !== setColumns.size;

    return (
      !filteredColumnsStr ||
      (setOptions.size === setColumns.size &&
        setOptions.isSubsetOf(setColumns) &&
        setColumns.isSubsetOf(setOptions))
    );
  });

  const open = Boolean(anchorEl);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const onHandleClickExport = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onHandleClose = () => {
    setAnchorEl(null);
  };

  const rowsWithoutId = () => {
    return rows.rows.map((row) => {
      const tmpRow = { ...row };
      delete tmpRow.id;
      return tmpRow;
    });
  };

  const onGenerateExcel = async () => {
    const excelColumnsTitlesArray = [...columns];

    downloadExcel(
      rowsWithoutId(),
      excelColumnsTitlesArray,
      `${tripname}_Passengers_${date.replaceAll(' ', '')}`
    );

    onHandleClose();
  };

  const onGenerateCSV = async () => {
    if (gridApiRef?.current) {
      await gridApiRef.current.exportDataAsCsv({
        fileName: `${tripname.replaceAll(' ', '-')}_Passengers_${date.replaceAll(' ', '')}`
      });
      onHandleClose();
    }
  };

  const onGeneratePDF = async () => {
    try {
      setPdfIsLoading(true);
      const response = await generatePassengersPdf({
        tripId: trip.tripid,
        columns
      });
      if (response?.data && Object.keys(response?.data).length > 0) {
        const downloadPdfUrl = response.data[0].result?.files[0].url;
        window.open(downloadPdfUrl);
      } else {
        ErrorToast(t('passengers:passengersPdfCouldNotBeGenerated'));
      }
    } catch (error) {
      ErrorToast(t('passengers:passengersPdfCouldNotBeGenerated'));
    } finally {
      setPdfIsLoading(false);
    }

    onHandleClose();
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    const allOptions = options
      .filter((field) => field.isoptional || field.isrequired)
      .map((field) => field.field);

    if (selectAllChecked && value.includes('Show All')) {
      setSelectAllChecked(false);
      setSelectedColumns([]);
    } else if (!selectAllChecked && value.includes('Show All')) {
      setSelectAllChecked(true);
      setSelectedColumns(allOptions);
    } else {
      setSelectedColumns(value);

      if (value.length && allOptions.length === value.length) {
        setSelectAllChecked(true);
      } else {
        setSelectAllChecked(false);
      }
    }
  };

  useEffect(() => {
    const updatedColumns = ['id', 'bookingid', ...selectedColumns];
    localStorage.setItem('filteredColumns', JSON.stringify(selectedColumns));
    onUpdateColumns(updatedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns]);

  return (
    <div className='passengers-header'>
      {/* <XGInputSearch
        className='passengers-inputFilter'
        id='passengers-inputFilter'
        name='search'
        type='text'
      /> */}
      <div className='passengers-header-dropdown'>
        <FormControl sx={{ m: 1, width: 300 }}>
          <Select
            sx={{ fontWeight: '500', fontFamily: 'Inter Variable' }}
            className='passengers-header-multiselect'
            multiple
            value={selectedColumns ?? []}
            onChange={handleChange}
            renderValue={() => 'Manage Columns'}
            MenuProps={MenuProps}
          >
            <MenuItem key='show-all' value='Show All'>
              <Checkbox checked={selectAllChecked} />
              <ListItemText primary='Show All' />
            </MenuItem>
            {options.map((field) => (
              <MenuItem
                key={field.field}
                label={field.title}
                value={field.field}
                disabled={field.ishidden}
              >
                <Checkbox checked={selectedColumns.indexOf(field.field) > -1} />
                <ListItemText primary={field.title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <XGButton
          endIcon={<KeyboardArrowDownIcon />}
          id='passagers-export-button'
          aria-controls={open ? 'passagers-export-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          text={t('common:exportAs')}
          type='secondary'
          size='large'
          variant='outlined'
          onClick={onHandleClickExport}
        />
        <Menu
          className='passagers-export__menu'
          id='passagers-export-menu'
          MenuListProps={{
            'aria-labelledby': 'passagers-export-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={onHandleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          style={{ minWidth: '165px' }}
        >
          <MenuItem
            className='passagers-export__menu-item'
            onClick={onGenerateExcel}
          >
            Excel
          </MenuItem>
          <MenuItem
            className='passagers-export__menu-item'
            onClick={onGenerateCSV}
          >
            CSV
          </MenuItem>
          <MenuItem
            disabled={pdfIsLoading}
            className='passagers-export__menu-item'
            onClick={onGeneratePDF}
            style={{ display: 'none' }}
          >
            PDF
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

PassengersHeader.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      title: PropTypes.string,
      sequence: PropTypes.number,
      datatype: PropTypes.string,
      display: PropTypes.string
    })
  ).isRequired,
  onUpdateColumns: PropTypes.func.isRequired,
  gridApiRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  columns: PropTypes.any,
  rows: PropTypes.any
};

export default PassengersHeader;
