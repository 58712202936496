//@ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tripimage: [
    {
      tripimageid: 0,
      seq: 0,
      imageurl: ''
    }
  ],
  totalPrice: 0,
  finalPrice: 0,
  createdbyuser: {
    userid: 0,
    login: ''
  },
  modifiedbyuser: {
    userid: 0,
    login: ''
  },
  tripid: 0,
  description: '',
  tripname: '',
  startdate: '',
  duration: 0,
  depositamount: '',
  depositdate: '',
  finalpaymentdat: '',
  notes: '',
  ispublic: true,
  tenant: {
    tenantid: 0,
    tenantname: ''
  },
  tripstatus: {
    tripstatusid: 0,
    tripstatusname: '',
    tripstatuscode: ''
  },
  contact: {
    contactid: 0,
    lastname: '',
    firstname: '',
    middlename: '',
    phone: '',
    email: ''
  },
  currency: {
    currencyid: 0,
    currencycode: ''
  },
  country: {
    countryid: 0,
    countryname: '',
    countrycode: ''
  },
  city: {
    cityid: 0,
    countryid: 0,
    cityname: '',
    citycode: ''
  },
  tripday: [
    {
      tripid: 0,
      tripdayid: 0,
      daynumber: 0,
      title: '',
      description: '',
      isSelected: false,
      city: null,
      tripdayimage: [
        {
          tripdayimageid: 0,
          seq: 0,
          imageurl: ''
        }
      ],
      lineitem: [
        {
          lineitemid: 0,
          title: '',
          description: '',
          seq: 0,
          qty: 0,
          duration: 0,
          unit: '',
          unitprice: '',
          isoptional: false,
          isselected: true,
          isdeleted: false,
          isEditing: false,
          confirmationstatusid: null,
          confirmationnumber: '',
          servicetypeid: 0,
          vendorid: null,
          createdbyuserid: 0,
          createddatetime: '',
          modifiedbyuserid: 0,
          modifieddatetime: '',
          city: null,
          cityid: null,
          ontime: null,
          vendor: null,
          servicetype: {
            servicetypeid: 0,
            servicetypename: '',
            servicetypecode: ''
          },
          lineitemimage: [
            {
              lineitemimageid: 0,
              seq: 0,
              imageurl: ''
            }
          ]
        }
      ]
    }
  ],
  tripcontent: []
};

const tripSlice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    createTrip: (state, action) => {
      return { ...action.payload };
    },
    updateTrip: (state, action) => {
      return { ...state, ...action.payload };
    },
    deleteTrip: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateSelectedDay: (state, action) => {
      state.tripday.map((day) => {
        day.isSelected = day.tripdayid === action.payload;
      });
    },
    updateSelectedContentItem: (state, action) => {
      state.tripcontent.map((day) => {
        day.isSelected = day.tripcontentid === action.payload;
      });
    },
    addDay: (state, action) => {
      const {
        tripid,
        tripdayid,
        daynumber,
        title,
        description,
        note,
        city,
        tripdayimage = []
      } = action.payload;
      state.tripday.push({
        tripid,
        tripdayid,
        daynumber,
        title,
        description,
        note,
        city,
        lineitem: [],
        tripdayimage,
        isSelected: state.tripday.length === 0
      });
      state.duration = state.duration + 1;
    },
    addTripContent: (state, action) => {
      const {
        tripid,
        tripcontentid,
        seq,
        title,
        description,
        tripcontentimage = []
      } = action.payload;
      state.tripcontent.push({
        tripid,
        tripcontentid,
        seq,
        title,
        description,
        tripcontentimage,
        isSelected: state.tripday.length === 0
      });
      state.duration = state.duration + 1;
    },
    editDay: (state, action) => {
      const { tripdayid, title, description, note } = action.payload;
      const tripDay = state.tripday.find((day) => day.tripdayid === tripdayid);

      if (tripDay) {
        tripDay.title = title ?? tripDay.title;
        tripDay.description = description ?? tripDay.description;
        tripDay.note = note ?? tripDay.note;
      }
    },
    editTripContent: (state, action) => {
      const { tripcontentid, title, description } = action.payload;
      const tripContent = state.tripcontent.find(
        (item) => item.tripcontentid === tripcontentid
      );

      if (tripContent) {
        tripContent.title = title;
        tripContent.description = description;
      }
    },
    updateDayImage: (state, action) => {
      const { tripdayid, tripdayimage } = action.payload;
      const tripDay = state.tripday.find((day) => day.tripdayid === tripdayid);

      if (tripDay) {
        tripDay.tripdayimage = tripdayimage;
      }
    },
    updateContentImage: (state, action) => {
      const { tripcontentid, tripcontentimage } = action.payload;

      const tripContent = state.tripcontent.find(
        (item) => item.tripcontentid === tripcontentid
      );

      if (tripContent) {
        tripContent.tripcontentimage = tripcontentimage;
      }
    },
    updateDaySequence: (state, action) => {
      const { tripdays = [] } = action.payload;

      const daysMap = tripdays.reduce((acc, item) => {
        acc[item.tripdayid] = item;
        return acc;
      }, {});

      return {
        ...state,
        tripday: state.tripday.map((day) => {
          if (daysMap[day?.tripdayid]) {
            return { ...day, daynumber: daysMap[day?.tripdayid]?.daynumber };
          }
          return day;
        })
      };
    },
    updateAdditionalContentSequence: (state, action) => {
      const { tripcontent = [] } = action.payload;

      const itemsMap = tripcontent.reduce((acc, item) => {
        acc[item.tripcontentid] = item;
        return acc;
      }, {});

      return {
        ...state,
        tripcontent: state.tripcontent.map((item) => {
          if (itemsMap[item?.tripcontentid]) {
            return {
              ...item,
              seq: itemsMap[item?.tripcontentid]?.seq
            };
          }
          return item;
        })
      };
    },
    addService: (state, action) => {
      const { tripdayid } = action.payload;
      const tripDay = state.tripday.find((day) => day.tripdayid === tripdayid);

      if (tripDay) {
        tripDay.lineitem.push(action.payload);
      }
    },
    removeTripDay: (state, action) => {
      const newTripData = action.payload;

      const tripDays = newTripData.tripday.map((item, index) => {
        return { ...item, isSelected: index === 0 };
      });

      return {
        ...state,
        duration: newTripData.duration,
        tripday: tripDays
      };
    },
    removeTripContent: (state, action) => {
      const tripcontentid = action.payload;

      const tripContentItems = state.tripcontent
        .filter((item) => item.tripcontentid !== tripcontentid)
        .map((item, index) => {
          return { ...item, isSelected: index === 0 };
        });

      return {
        ...state,
        tripcontent: tripContentItems
      };
    },
    removeService: (state, action) => {
      const { tripdayid, lineitemid } = action.payload;

      return {
        ...state,
        tripday: state.tripday.map((day) => {
          if (day.tripdayid === tripdayid) {
            return {
              ...day,
              lineitem: day.lineitem.filter(
                (item) => item.lineitemid !== lineitemid
              )
            };
          }
          return day;
        })
      };
    },
    updateDayServiceImage: (state, action) => {
      const { tripdayid, lineitemid, lineitemimage } = action.payload;
      const updatedTripday = state.tripday.map((day) => {
        if (day.tripdayid !== tripdayid) {
          return day;
        }

        const updatedLineitem = day.lineitem.map((item) => {
          if (item.lineitemid !== lineitemid) {
            return item;
          }

          return { ...item, lineitemimage };
        });

        return {
          ...day,
          lineitem: updatedLineitem
        };
      });

      return {
        ...state,
        tripday: updatedTripday
      };
    },
    updateDayService: (state, action) => {
      const {
        tripdayid,
        lineitemid,
        servicetypeid,
        title,
        description,
        servicetype
      } = action.payload;

      const updatedTripday = state.tripday.map((day) => {
        if (day.tripdayid !== tripdayid) {
          return day;
        }

        const updatedLineitem = day.lineitem.map((item) => {
          if (item.lineitemid !== lineitemid) {
            return item;
          }

          return {
            ...item,
            servicetypeid,
            servicetype,
            title,
            description
          };
        });

        return {
          ...day,
          lineitem: updatedLineitem
        };
      });

      return {
        ...state,
        tripday: updatedTripday
      };
    },
    updateServicesSequence: (state, action) => {
      const { tripdayid, lineitems = [] } = action.payload;

      const lineItemsMap = lineitems.reduce((acc, item) => {
        acc[item.lineitemid] = item;
        return acc;
      }, {});

      return {
        ...state,
        tripday: state.tripday.map((day) => {
          if (day.tripdayid !== tripdayid) {
            return day;
          }

          return {
            ...day,
            lineitem: day.lineitem.map((item) => {
              const foundLineItem = lineItemsMap[item.lineitemid];
              return foundLineItem ? { ...item, seq: foundLineItem.seq } : item;
            })
          };
        })
      };
    },
    setEditingService: (state, action) => {
      const { tripdayid, lineitemid } = action.payload;

      const updatedTripday = state.tripday.map((day) => {
        if (day.tripdayid !== tripdayid) {
          return day;
        }

        const updatedLineitem = day.lineitem.map((item) => ({
          ...item,
          isEditing: item.lineitemid === lineitemid
        }));

        return {
          ...day,
          lineitem: updatedLineitem
        };
      });

      return {
        ...state,
        tripday: updatedTripday
      };
    }
  }
});

const { actions, reducer } = tripSlice;

export const {
  createTrip,
  updateTrip,
  deleteTrip,
  updateSelectedDay,
  updateSelectedContentItem,
  updateDayService,
  addDay,
  addTripContent,
  editDay,
  editTripContent,
  addService,
  removeTripDay,
  removeTripContent,
  removeService,
  setEditingService,
  updateDayServiceImage,
  updateDayImage,
  updateContentImage,
  updateServicesSequence,
  updateDaySequence,
  updateAdditionalContentSequence
} = actions;

export default reducer;
