// @ts-nocheck
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { XGButton } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import './TripPassengerFieldModalActions.css';

/**
 * Render custom actions for the passenger field modal component.
 *
 * @param {function} onCancel - Callback function for cancel action
 * @param {function} onSubmit - Callback function for submit action
 * @param {boolean} isSubmitting - Flag indicating if form is currently submitting
 * @return {JSX.Element} The custom actions for the passenger field modal component.
 */
const TripPassengerFieldModalActions = ({
  onCancel,
  onSubmit,
  isSubmitting
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Box
      data-testid='update-modal-actions'
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '10px',
        gap: '16px'
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <XGButton
          style={{
            width: '100px'
          }}
          data-testid='update-action-cancel-button'
          id='update-action-cancel-button'
          text={t('cancel', { ns: 'common' })}
          type='secondary'
          size='large'
          variant='outlined'
          onClick={onCancel}
          disabled={isSubmitting}
        />
        <Box sx={{ position: 'relative' }}>
          <XGButton
            data-testid='update-action-confirm-button'
            id='update-action-confirm-button'
            text={t('done', { ns: 'common' })}
            type='primary'
            size='large'
            variant='contained'
            onClick={onSubmit}
            disabled={isSubmitting}
            style={{
              width: '100px'
            }}
          />
          {isSubmitting && (
            <CircularProgress
              id='update-actions-loader'
              size={24}
              sx={{
                color: blue[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px'
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

TripPassengerFieldModalActions.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool
};

export default TripPassengerFieldModalActions;
