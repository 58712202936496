// @ts-nocheck
import { Box, InputLabel, FormControl, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHeadingFont,
  setBodyFont
} from '../../../../redux/slices/font/font';
import FontDataConfig from '../FontDataConfig/FontDataConfig';
import PropTypes from 'prop-types';
import './FontStyleConfig.css';

/**
 * Functional React component for rendering font style configuration.
 *
 * @namespace Components
 *
 * @param {object} props - The properties of the component.
 * @param {function} props.onFontChange - The handler for font changes.
 *
 * @returns {JSX.Element} - The rendered component.
 */
const FontStyleConfig = ({ onFontChange }) => {
  const dispatch = useDispatch();
  const headingFont = useSelector((state) => state.font.headingFont) || 'Inter';
  const bodyFont = useSelector((state) => state.font.bodyFont) || 'Inter';

  const { t } = useTranslation(['settings']);

  const headingFonts = [
    { family: 'Inter', weights: [400, 700] },
    { family: 'Montserrat', weights: [400, 700] },
    { family: 'Playfair Display', weights: [400, 700] },
    { family: 'Lobster', weights: [400] },
    { family: 'Merriweather', weights: [400, 700] },
    { family: 'Oswald', weights: [400, 700] },
    { family: 'Raleway', weights: [400, 700] },
    { family: 'CopperplateCC', weights: [700] }
  ];

  const bodyFonts = [
    { family: 'Inter', weights: [400, 700] },
    { family: 'Montserrat', weights: [400, 700] },
    { family: 'Lora', weights: [400, 700] },
    { family: 'Lato', weights: [400, 700] },
    { family: 'Open Sans', weights: [400, 700] },
    { family: 'Roboto', weights: [400, 700] },
    { family: 'PT Sans', weights: [400, 700] },
    { family: 'Poppins', weights: [400, 700] },
    { family: 'Bitter', weights: [400, 700] },
    { family: 'Playfair Display', weights: [400, 700] },
    { family: 'GFS Didot', weights: [400, 700] }
  ];

  const handleFontChange = (type, font) => {
    if (type === 'heading') {
      dispatch(setHeadingFont(font));
    } else if (type === 'body') {
      dispatch(setBodyFont(font));
    }
    if (onFontChange) onFontChange(type, font);
  };

  return (
    <Box className='font-style-config-container'>
      <Box className='font-config-box'>
        <FontDataConfig fonts={headingFonts}>
          {(loadedFonts) => (
            <>
              <InputLabel className='font-label'>{t('headingfont')}</InputLabel>
              <Box className='font-select-box'>
                <FormControl className='font-form-control'>
                  <Select
                    value={headingFont}
                    onChange={(event) =>
                      handleFontChange('heading', event.target.value)
                    }
                    displayEmpty
                    className='xg-select-input-component'
                  >
                    {loadedFonts.map((font) => (
                      <MenuItem key={font} value={font}>
                        <span style={{ fontFamily: font }}>{font}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
        </FontDataConfig>
      </Box>
      <Box className='font-config-box'>
        <FontDataConfig fonts={bodyFonts}>
          {(loadedFonts) => (
            <>
              <InputLabel className='font-label'>{t('bodyfont')}</InputLabel>
              <Box className='font-select-box'>
                <FormControl className='font-form-control'>
                  <Select
                    value={bodyFont}
                    onChange={(event) =>
                      handleFontChange('body', event.target.value)
                    }
                    displayEmpty
                    className='xg-select-input-component'
                  >
                    {loadedFonts.map((font) => (
                      <MenuItem key={font} value={font}>
                        <span style={{ fontFamily: font }}>{font}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
        </FontDataConfig>
      </Box>
    </Box>
  );
};

FontStyleConfig.propTypes = {
  onFontChange: PropTypes.func
};

export default FontStyleConfig;
