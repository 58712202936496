// @ts-nocheck
import { useTranslation } from 'react-i18next';
import { CaretDown } from '@phosphor-icons/react';
import PassengersCheckBoxForm from '../PassengersCheckBoxForm/PassengersCheckBoxForm';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  InputLabel,
  FormHelperText
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { XGInput, XGDatePicker, XGTextarea, XGAutocomplete } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import './BookingPassengerForm.css';
import PassengersRadioForm from '../PassengersRadioForm/PassengersRadioForm';
import {
  DATE,
  TEXTAREA,
  CHECKBOX,
  RADIOBUTTON,
  BOOLEAN,
  PHONE
} from '../../../../constants/passengers';

/**
 * Functional React component for rendering a custom  passenger form component .
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the passenger form component.
 * @param {Object} form - The form object.
 * @param {number} sequence - The sequence of the form.
 * @param {Function} [props.handleOnChange] - The function to handle the onChange event.
 *
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the passenger form component.
 */

const BookingPassengerForm = ({
  countries,
  id,
  form,
  sequence,
  handleOnChange,
  ...rest
}) => {
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const { formId, title, fields = [] } = form;

  return (
    <Box id={id} className='passenger-form__container' {...rest}>
      <Accordion className='passenger-form__accordion' defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDown size={24} weight='bold' />}
          aria-controls='passenger-form-panel-content'
          id='passenger-form-panel-header'
          className='passenger-form__accordion-summary'
        >
          <span className='text-lg-semibold passenger-form__header'>
            <span className='passenger-form__step'>{sequence}.</span>
            {t('passengerInformation')} - {title || ''}
          </span>
        </AccordionSummary>
        <AccordionDetails className='passenger-form__accordion-details'>
          <Grid container className='passenger-form__grid'>
            {fields &&
              fields.map((field, index) => {
                const { fieldtype } = field;
                const fieldDescription = field?.description
                  ? ` - ${field?.description.trim()}`
                  : '';
                return fieldtype?.key === RADIOBUTTON ||
                  fieldtype?.key === BOOLEAN ? (
                  <PassengersRadioForm
                    key={index}
                    formId={id}
                    field={field}
                    handleChange={(value) => {
                      handleOnChange(formId, field?.fieldId, value);
                    }}
                  />
                ) : fieldtype?.key === PHONE ? (
                  <Box
                    className='w-100 passenger-form__input-container'
                    key={index}
                  >
                    <InputLabel
                      className='xg-input-label phone-input-label'
                      htmlFor={`passenger-${field?.key}-input-${id}`}
                    >
                      {`${field?.title}${field?.isrequired ? '*' : ''}${fieldDescription}`}
                    </InputLabel>
                    <MuiTelInput
                      className={`phone-input ${field?.error ? ' passenger-form__phone-input-error ' : ''}`}
                      value={field?.value}
                      name={field?.key}
                      id={`passenger-${field?.key}-input-${id}`}
                      onChange={(value) =>
                        handleOnChange(formId, field?.fieldId, value)
                      }
                      defaultCountry='US'
                      inputProps={{
                        autoComplete: 'off',
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                      }}
                    />
                    {field?.error && (
                      <FormHelperText className='input-form-helper-text--error'>
                        {field?.error}
                      </FormHelperText>
                    )}
                  </Box>
                ) : fieldtype?.key === CHECKBOX ? (
                  <PassengersCheckBoxForm
                    key={index}
                    id={`passenger-${field?.key}-input-${id}`}
                    field={field}
                    selectedOptions={
                      field?.value ? field?.value?.split(',') : []
                    }
                    formId={formId}
                    handleOnChange={handleOnChange}
                  />
                ) : fieldtype?.key === TEXTAREA ? (
                  <Box
                    className='w-100 passenger-form__input-container'
                    key={index}
                  >
                    <XGTextarea
                      placeholder={`${t('common:enter')} ${field?.title?.toLowerCase()}`}
                      label={`${field?.title}${field?.isrequired ? '*' : ''}${fieldDescription}`}
                      id={`passenger-${field?.key}-input-${id}`}
                      type='text'
                      name={field?.key}
                      value={field?.value}
                      onChange={(evt) =>
                        handleOnChange(formId, field?.fieldId, evt.target.value)
                      }
                      inputProps={{
                        maxLength: 1000
                      }}
                      error={field?.error ? true : false}
                      helpertext={field?.error}
                      minRows={3}
                    />
                    {field?.error && (
                      <FormHelperText className='input-form-helper-text--error'>
                        {field?.error}
                      </FormHelperText>
                    )}
                  </Box>
                ) : fieldtype?.key === DATE ? (
                  <Box
                    className='w-100 passenger-form__input-container'
                    key={index}
                  >
                    <XGDatePicker
                      className={`${field?.error ? ' passenger-form__input-error ' : ''}`}
                      style={{ width: '100%' }}
                      format={`${t('common:dateFormat')}`}
                      id={`passenger-${field?.key}-input-${id}`}
                      name={field?.key}
                      label={`${field?.title}${field?.isrequired ? '*' : ''}${fieldDescription}`}
                      value={field?.value ? new Date(field?.value) : null}
                      onChange={(selectedDate) => {
                        handleOnChange(
                          formId,
                          field?.fieldId,
                          selectedDate?.toISOString()
                        );
                      }}
                      error={field?.error ? true : false}
                      hint={field?.error}
                    />
                  </Box>
                ) : fieldtype?.key === 'country' ? ( //TODO: Define this in the backend
                  <Box
                    className='w-100 passenger-form__input-container'
                    key={index}
                  >
                    <XGAutocomplete
                      className={`autocomplete ${field?.error ? ' passenger-form__phone-input-error ' : ''}`}
                      disableClearable={false}
                      placeholder={t('searchCountry')}
                      name={field?.key}
                      label={`${field?.title}${field?.isrequired ? '*' : ''}${fieldDescription}`}
                      options={countries}
                      isOptionEqualToValue={(option, value) =>
                        option.title === value?.title
                      }
                      onChange={(event, newValue) => {
                        const { countrycode } = newValue || {};
                        handleOnChange(formId, field?.fieldId, countrycode);
                      }}
                    />
                    {field?.error && (
                      <FormHelperText className='input-form-helper-text--error'>
                        {field?.error}
                      </FormHelperText>
                    )}
                  </Box>
                ) : (
                  <Box
                    className='w-100 passenger-form__input-container'
                    key={index}
                  >
                    <XGInput
                      error={field?.error ? true : false}
                      hint={field?.error}
                      label={`${field?.title}${field?.isrequired ? '*' : ''}${fieldDescription}`}
                      name={field?.key}
                      placeholder={`${t('common:enter')} ${field?.title?.toLowerCase()}`}
                      id={`passenger-${field?.key}-input-${id}`}
                      type={'text'}
                      value={field?.value}
                      onChange={(evt) =>
                        handleOnChange(
                          formId,
                          field?.fieldId,
                          evt.target.value,
                          fieldtype?.key
                        )
                      }
                      inputProps={{
                        maxLength: 250
                      }}
                    />
                  </Box>
                );
              })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

BookingPassengerForm.propTypes = {
  handleOnChange: PropTypes.func,
  form: PropTypes.shape({
    formId: PropTypes.string,
    title: PropTypes.string,
    fields: PropTypes.array
  }),
  id: PropTypes.string.isRequired,
  sequence: PropTypes.number,
  countries: PropTypes.array
};
export default BookingPassengerForm;
