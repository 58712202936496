// @ts-nocheck
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

/**
 * Render the trip passengers field skeleton
 *
 * @param {string} id - Component ID
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 * @return {JSX.Element} The the trip passengers field skeleton component
 */
const TripPassengersFieldSkeleton = ({ id, ...rest }) => {
  return (
    <Box
      id={id}
      data-testid='trip-passenger-field-skeleton'
      style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      {...rest}
    >
      <Skeleton
        variant='rectangular'
        width={'200px'}
        height={42}
        sx={{
          marginBottom: '24px',
          borderRadius: '8px'
        }}
      />
      <Skeleton
        variant='rectangular'
        width={'100%'}
        height={52}
        sx={{ borderBottom: '1px solid #EAECF0', backgroundColor: '#f5f5f5' }}
      />
      {Array.from(new Array(6)).map((_, index) => (
        <Skeleton
          key={index}
          variant='rectangular'
          width={'100%'}
          height={75}
          sx={{ borderBottom: '1px solid #EAECF0', backgroundColor: '#f5f5f5' }}
        />
      ))}
    </Box>
  );
};

TripPassengersFieldSkeleton.propTypes = {
  id: PropTypes.string
};

export default TripPassengersFieldSkeleton;
