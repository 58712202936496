// @ts-nocheck
import Box from '@mui/material/Box';
import Editor from '../../../Editor/Editor';
import { useTranslation } from 'react-i18next';
import { Sun, File } from '@phosphor-icons/react';
import { XGModal } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import './LibraryPreview.css';

/**
 * Functional React component for rendering a custom library item preview.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {boolean} [props.item] - The library item.
 * @param {boolean} [props.isOpenModal] - Open modal state.
 * @param {function} [props.onCancel] - Function to handle closing modal.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the library item preview.
 */

const LibraryPreview = ({ item, isOpenModal, onCancel, ...rest }) => {
  const { t } = useTranslation(['common', 'library']);
  const { contentid = 0, contentimage = [], code = 'day', note } = item || {};
  const title = item?.title || t('noTitle');
  const description = item?.description?.trim() || t('noDescription');
  const imageUrl = contentimage?.[0]?.imageurl || null;

  return (
    <XGModal
      id={`library-preview-${contentid}`}
      allowClosing={true}
      data-testid={`library-preview-${contentid}`}
      actions={<></>}
      dismissable={true}
      open={isOpenModal}
      title={
        <div className='library-preview__title-container'>
          {code === 'day' ? (
            <Sun color='#344054' size={28} />
          ) : (
            <File color='#344054' size={28} />
          )}
          {title}
        </div>
      }
      maxWidth='lg'
      scroll='paper'
      onClose={onCancel}
      {...rest}
    >
      <Box className='library-preview__modal-container'>
        {imageUrl ? (
          <Box className='library-preview__image-container '>
            <img
              className='library-preview__image'
              src={imageUrl}
              alt={title}
              draggable={false}
            />
          </Box>
        ) : (
          <> </>
        )}

        <Box className='library-preview__editor-container'>
          {item?.description?.trim() !== '' && (
            <p className='MuiTypography-root MuiTypography-body2 _create-trip__date-label'>
              <span className='w-50'>{t('trip:description')} </span>
            </p>
          )}
          <Editor
            editable={false}
            isPreviewing={true}
            content={description}
            id={`library-preview-editor-description-${contentid}`}
            setDescription={() => {}}
            setMarkdown={() => {}}
            setIsPreviewing={() => {}}
            showActions={false}
            onCancelAction={() => {}}
            onSaveAction={() => {}}
            generatePrompt={<></>}
            isEditorDisabled={false}
          />
        </Box>

        {code === 'day' && note ? (
          <Box className='library-preview__editor-container'>
            {note && note !== '' && (
              <p className='MuiTypography-root MuiTypography-body2 _create-trip__date-label'>
                <span className='w-50'>{t('trip:internalNotes')} </span>
              </p>
            )}
            <Editor
              editable={false}
              isPreviewing={true}
              content={note}
              id={`library-preview-editor-note-${contentid}`}
              setDescription={() => {}}
              setMarkdown={() => {}}
              setIsPreviewing={() => {}}
              showActions={false}
              onCancelAction={() => {}}
              onSaveAction={() => {}}
              generatePrompt={<></>}
              isEditorDisabled={false}
            />
          </Box>
        ) : (
          <> </>
        )}
      </Box>
    </XGModal>
  );
};

LibraryPreview.propTypes = {
  item: PropTypes.object,
  isOpenModal: PropTypes.bool,
  onCancel: PropTypes.func
};

export default LibraryPreview;
