//Supported field types for passenger form
export const DATE = 'date';
export const EMAIL = 'email';
export const TEXTAREA = 'textarea';
export const CHECKBOX = 'checkbox';
export const RADIOBUTTON = 'radiobutton';
export const NUMBER = 'number';
export const PHONE = 'phone';
export const TEXTBOX = 'textbox';
export const BOOLEAN = 'boolean';
