// @ts-nocheck

import BookingPassengerActions from '../BookingPassengerActions/BookingPassengerActions';
import BookingPassengerForm from '../BookingPassengerForm/BookingPassengerForm';
import { NUMBER } from '../../../../constants/passengers';
import { useState, useEffect } from 'react';
import { updatePassengerField } from '../../../../redux/slices/booking/booking';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { getCountries } from '../../../../services/country/country';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom booking passengers component .
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the booking passengers component.
 * @param {boolean} isSubmitting - Flag indicating if form is currently submitting
 * @param {Function} [props.onHandleBack] - The function to handle the back action.
 * @param {Function} [props.onHandleNext] - The function to handle the next action.
 *
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the booking passengers component.
 */

const BookingPassengerPanel = ({
  id,
  isSubmitting,
  onHandleBack,
  onHandleNext,
  ...rest
}) => {
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const dispatch = useDispatch();
  const booking = useSelector((state) => state.booking);
  const [countries, setCountries] = useState([]);
  const passengerForms = booking?.passengerForms || [];
  const shouldGetCountries = passengerForms.length
    ? passengerForms[0]?.fields?.find(
        (field) =>
          field?.field === 'country' || field?.field === 'passportnationality'
      )
    : false;

  useEffect(() => {
    let isMounted = true;

    const fetchCountries = async () => {
      try {
        const response = await getCountries();
        const countriesList = response?.data || [];

        if (response && response?.data?.length) {
          const mappedCountries = countriesList.map((country) => ({
            ...country,
            title: country?.countryname
          }));

          if (isMounted) {
            setCountries(mappedCountries);
          }
        }
      } catch (error) {
        setCountries([]);
      }
    };

    if (shouldGetCountries) {
      fetchCountries();
    }

    return () => {
      isMounted = false;
    };
  }, [shouldGetCountries]);

  const handleOnChange = (formId, fieldId, newValue, type) => {
    if ((type === NUMBER && !isNaN(newValue?.trim())) || type !== NUMBER) {
      dispatch(
        updatePassengerField({
          formId,
          fieldId,
          newValue,
          error: null
        })
      );
    }
  };

  return (
    <Box
      id={id}
      data-testid='booking-passengers-form'
      {...rest}
      className='book-now-modal__payment-options'
    >
      <div className='book-now-modal__section-header'>
        <span className='h4-semibold '>{t('passengers')}</span>
      </div>
      <div className='book-now-modal__plans-container'>
        {passengerForms &&
          passengerForms.map((form, index) => {
            const { formId } = form;
            return (
              <BookingPassengerForm
                key={formId}
                id={`booking-passenger-form-${formId}`}
                form={form}
                handleOnChange={handleOnChange}
                sequence={index + 1}
                countries={countries}
              />
            );
          })}
        <BookingPassengerActions
          onBack={onHandleBack}
          onNext={onHandleNext}
          disabledConfirm={isSubmitting}
          isSubmitting={isSubmitting}
        />
      </div>
    </Box>
  );
};

BookingPassengerPanel.propTypes = {
  onHandleNext: PropTypes.func,
  onHandleBack: PropTypes.func,
  id: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool
};
export default BookingPassengerPanel;
