// @ts-nocheck
import { Box, Grid, InputLabel, FormHelperText } from '@mui/material';
import { XGRadio } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom gender options component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Function} [props.handleChange] - The function to handle the change event.
 * @param {string} [props.formId] - The form ID.
 * @param {Object} props.field - The field object.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the gender options component.
 */
const PassengersRadioForm = ({ field, handleChange, formId, ...rest }) => {
  const { value, key, isrequired, title, error } = field;
  const options = [...(field?.trippassengerfieldoption ?? [])];
  const fieldDescription = field?.description
    ? ` - ${field?.description.trim()}`
    : '';

  const sortedOptions = options.sort((a, b) => a.sequence - b.sequence);

  const handleRadioChange = (event) => {
    const { value } = event.target;
    handleChange(value);
  };

  return (
    <Box className='w-100' {...rest}>
      <InputLabel
        className='xg-input-label phone-input-label'
        htmlFor={`passenger-${key}-input-${formId}`}
      >
        {`${title ?? ''}${isrequired ? '*' : ''}${fieldDescription}`}
      </InputLabel>
      <Grid item xs={12} padding='0 16px'>
        <Box
          style={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column'
          }}
        >
          {sortedOptions &&
            sortedOptions.length > 0 &&
            sortedOptions.map((option) => (
              <XGRadio
                key={option?.trippassengerfieldoptionid}
                id={`passenger-gender-option-${option?.title}`}
                name={key}
                label={option?.title}
                size='small'
                value={option?.title}
                checked={value === option?.title}
                onChange={handleRadioChange}
              />
            ))}
        </Box>
      </Grid>
      {error && (
        <FormHelperText className='input-form-helper-text--error'>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

PassengersRadioForm.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  formId: PropTypes.string,
  field: PropTypes.object
};

export default PassengersRadioForm;
