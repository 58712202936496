import request from '../request';

export const createBooking = (data = {}) =>
  request.post('bookings', data).then((res) => res);

export const getBookingById = (bookingId = '', bookingKey = '') =>
  request
    .get(`bookings/${bookingId}?bookingkey=${bookingKey}`)
    .then((res) => res);

export const createPassenger = (data = {}) =>
  request.post('bookingpassengers', data).then((res) => res);

export const createPassengers = (bookingid = '', data = {}) =>
  request.post(`bookings/${bookingid}/passengers`, data).then((res) => res);

export const updateBookingPassenger = async (
  tripid = '',
  bookingid = '',
  bookingpassengerid = '',
  data = {}
) => {
  return request
    .put(
      `/trips/${tripid}/bookings/${bookingid}/bookingpassengers/${bookingpassengerid}/passengerfields`,
      data
    )
    .then((res) => res);
};

export const generatePassengersPdf = async (data = {}) => {
  const resp = await request
    .post('bookingpassengers/pdf', data)
    .then((res) => res);
  return resp;
};

export const getTripBookingPassenger = (tripid = '') => {
  return request.get(`/trips/${tripid}/bookingpassengers`).then((res) => res);
};
