//@ts-nocheck
import PassengerFieldsTable from '../PassengersFieldsTable/PassengersFieldsTable';
import PassengerFieldModal from '../PassengerFieldModal/PassengerFieldModal';
import PassengersFieldSkeleton from '../PassengersFieldSkeleton/PassengersFieldSkeleton';
import DeleteModal from '../../../Trip/DeleteModal/DeleteModal';
import { Box } from '@mui/material';
import {
  getPassengerFields,
  createPassengerField,
  updatePassengerField,
  deletePassengerField,
  updatePassengerFieldSequence,
  updatePassengerFieldStatus
} from '../../../../services/tenant/tenant';
import { getFieldTypes } from '../../../../services/fieldtype/fieldtype';
import { useState, useEffect } from 'react';
import { PlusCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { XGButton } from '@xg-cl/xg-cl';
import { ErrorToast, SuccessToast } from '../../../../utils/alerts';
import {
  REQUIRED,
  OPTIONAL,
  HIDDEN
} from '../../../../constants/passengerFieldStatuses';
import './PassengerFieldsPanel.css';
import * as PropTypes from 'prop-types';

const initialFormData = {
  selectedFieldType: null,
  isEditing: false,
  title: '',
  description: '',
  isrequired: false,
  ishidden: false,
  isoptional: true, // Optional by default
  passengerfieldoption: [],
  passengerfieldconfig: null,
  error: {
    title: '',
    description: ''
  }
};

/**
 * Render the passenger fields panel
 *
 * @param {string} id - The component unique identifier
 * @return {JSX.Element} The passenger fields panel component
 */
const PassengerFieldsPanel = ({ id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddAnswerDisabled, setIsAddAnswerDisabled] = useState(false);
  const [deletingPassengerId, setDeletingPassengerId] = useState(null);
  const [passengerFields, setPassengerFields] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [fieldForm, setFieldForm] = useState(initialFormData);
  const { t } = useTranslation(['passengers', 'common', 'errors']);

  const fetchPassengerFields = async () => {
    try {
      const { data = [] } = await getPassengerFields();

      if (data?.length > 0) {
        setPassengerFields(data);
      }
    } catch (error) {
      setPassengerFields([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFieldTypes = async () => {
    try {
      const { data = [] } = await getFieldTypes();

      if (data?.length > 0) {
        const fieldTypesList = data.map((field) => {
          return {
            ...field,
            id: field.fieldtypeid,
            label: field?.name,
            value: field?.key
          };
        });

        setFieldTypes(fieldTypesList);
        setFieldForm((prev) => ({
          ...prev,
          selectedFieldType: fieldTypesList[0]
        }));
      }
    } catch (error) {
      setFieldTypes([]);
    }
  };

  const resetForm = () => {
    setFieldForm({
      ...initialFormData,
      selectedFieldType: fieldTypes && fieldTypes?.length ? fieldTypes[0] : null
    });
  };

  const resetErrors = () => {
    setFieldForm((prev) => ({
      ...prev,
      error: null
    }));
  };

  const isFormValid = () => {
    resetErrors();
    let isValid = true;

    const title = fieldForm?.title?.trim();
    const selectedFieldType = fieldForm?.selectedFieldType;
    const fieldOptions = fieldForm?.passengerfieldoption ?? [];

    if (!title) {
      setFieldForm((prev) => ({
        ...prev,
        error: { ...prev.error, title: t('fieldTitleRequired') }
      }));

      isValid = false;
    }

    if (!selectedFieldType) {
      setFieldForm((prev) => ({
        ...prev,
        error: {
          ...prev.error,
          selectedFieldType: t('fieldTypeRequired')
        }
      }));

      isValid = false;
    }

    if (selectedFieldType?.allowoptions && !fieldOptions?.length) {
      setFieldForm((prev) => ({
        ...prev,
        error: { ...prev.error, fieldOptions: t('fieldAnswersRequired') }
      }));

      isValid = false;
    }

    if (selectedFieldType?.allowoptions && fieldOptions?.length) {
      const newOptions = fieldOptions.map((option) => {
        const { title } = option;

        if (!title?.trim()) {
          isValid = false;
          return {
            ...option,
            error: t('answerTitleRequired')
          };
        }

        return option;
      });

      setFieldForm((prev) => ({
        ...prev,
        passengerfieldoption: newOptions
      }));
    }

    return isValid;
  };

  const onCloseModal = () => {
    resetForm();
    setIsAddAnswerDisabled(false);
    setIsOpenModal(false);
  };

  const onOpenModal = () => {
    resetForm();
    setIsAddAnswerDisabled(false);
    setIsOpenModal(true);
    setIsSubmitting(false);
  };

  const moveJustAddedFieldToTop = async (passengerfieldid) => {
    const customFields = passengerFields?.filter((field) => {
      return !field?.issystemfield;
    });

    const systemFields = passengerFields
      ?.filter((field) => {
        return field?.issystemfield;
      })
      .sort((a, b) => a?.sequence - b?.sequence);

    const justAddedFieldSequence =
      systemFields && systemFields?.length
        ? systemFields[systemFields?.length - 1]?.sequence + 1
        : 1;

    if (customFields && customFields?.length && passengerfieldid) {
      await updatePassengerFieldSequence(passengerfieldid, {
        sequence: justAddedFieldSequence
      });
    }
  };

  const onSavePassengerField = async () => {
    setIsSubmitting(true);

    try {
      const isFieldValid = isFormValid();

      if (!isFieldValid) {
        return;
      } else {
        const { isEditing = false, passengerfieldid = null } = fieldForm;
        const fieldOptions =
          fieldForm?.passengerfieldoption?.map((option) => {
            const { title, sequence, isselected } = option;
            return { title, sequence, isselected };
          }) ?? [];

        const payload = {
          fieldtypeid: fieldForm?.selectedFieldType?.fieldtypeid ?? null,
          title: fieldForm?.title ?? '',
          description: fieldForm?.description ?? '',
          isrequired: fieldForm?.isrequired ?? false,
          ishidden: fieldForm?.ishidden ?? false,
          isoptional: fieldForm?.isoptional ?? false,
          passengerfieldoption: fieldOptions ?? [],
          passengerfieldconfig: fieldForm?.passengerfieldconfig ?? null
        };

        delete payload?.passengerfieldconfig?.passengerfieldconfigid;

        if (!isEditing) {
          const { data, error } = await createPassengerField(payload);

          if (!data || error) {
            ErrorToast(t('errors:passengerFieldCouldNotBeCreated'));
          } else {
            const { passengerfieldid } = data;

            await moveJustAddedFieldToTop(passengerfieldid);
            await fetchPassengerFields();
            setIsOpenModal(false);
            SuccessToast(t('passengerFieldCreatedSuccessfully'));
          }
        } else {
          const { data, error } = await updatePassengerField(
            payload,
            passengerfieldid
          );

          if (!data || error) {
            ErrorToast(t('errors:passengerFieldCouldNotBeUpdated'));
          } else {
            await fetchPassengerFields();
            setIsOpenModal(false);
            SuccessToast(t('passengerFieldUpdatedSuccessfully'));
          }
        }
      }
    } catch (error) {
      if (fieldForm?.isEditing) {
        ErrorToast(t('errors:passengerFieldCouldNotBeUpdated'));
      } else {
        ErrorToast(t('errors:passengerFieldCouldNotBeCreated'));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOnEditField = (fieldId) => {
    const foundField = passengerFields.find(
      (field) => field.passengerfieldid === fieldId
    );

    if (foundField) {
      const {
        fieldtype,
        title = '',
        description = '',
        isrequired,
        ishidden,
        isoptional,
        passengerfieldoption = [],
        passengerfieldconfig = null
      } = foundField;

      const foundFieldType = fieldTypes.find(
        (fieldType) => fieldType?.fieldtypeid === fieldtype?.fieldtypeid
      );

      const fieldData = {
        passengerfieldid: fieldId,
        selectedFieldType: foundFieldType ?? null,
        isEditing: true,
        title,
        description,
        isrequired,
        ishidden,
        isoptional,
        passengerfieldoption,
        passengerfieldconfig,
        error: {}
      };

      setFieldForm(fieldData);
      setIsOpenModal(true);
      setIsSubmitting(false);
      setIsAddAnswerDisabled(foundFieldType?.key === 'boolean');
    }
  };

  const handleOnDeleteField = (fieldId) => {
    setIsDeleteModalOpen(true);
    setDeletingPassengerId(fieldId);
  };

  const handleDeletePassengerFieldConfirmation = async () => {
    try {
      setIsSubmitting(true);
      const { data, error } = await deletePassengerField(deletingPassengerId);

      if (!data || error) {
        ErrorToast(t('errors:passengerFieldCouldNotBeDeleted'));
      } else {
        await fetchPassengerFields();
        SuccessToast(t('passengerFieldDeletedSuccessfully'));
      }
    } catch (error) {
      ErrorToast(t('errors:passengerFieldCouldNotBeDeleted'));
    } finally {
      setIsDeleteModalOpen(false);
      setDeletingPassengerId(null);
      setIsSubmitting(false);
    }
  };

  const revertPassengerFieldOrder = (
    sourcePassengerFieldId = 0,
    destinationPassengerFieldId = 0
  ) => {
    const newPassengerFields = [...passengerFields];

    const sourcePassengerFieldIndex = passengerFields.findIndex(
      (field) => field.passengerfieldid === sourcePassengerFieldId
    );

    const destinationPassengerFieldIndex = passengerFields.findIndex(
      (field) => field.passengerfieldid === destinationPassengerFieldId
    );

    const tempField = newPassengerFields[sourcePassengerFieldIndex];
    newPassengerFields[sourcePassengerFieldIndex] =
      newPassengerFields[destinationPassengerFieldIndex];
    newPassengerFields[destinationPassengerFieldIndex] = tempField;

    setPassengerFields(newPassengerFields);
  };

  const revertPassengerFieldStatus = (originalPassengerField = null) => {
    const { isrequired, isoptional, ishidden, passengerfieldid } =
      originalPassengerField;
    const newPassengerFields = [...passengerFields];

    const index = newPassengerFields.findIndex(
      (field) => field?.passengerfieldid === passengerfieldid
    );

    if (index !== -1) {
      const updatedField = {
        ...newPassengerFields[index],
        isrequired,
        isoptional,
        ishidden
      };
      newPassengerFields[index] = updatedField;
      setPassengerFields(newPassengerFields);
    }
  };

  const handleUpdateSequence = async (
    sourcePassenger,
    destinationPassenger
  ) => {
    const { passengerfieldid: sourcePassengerFieldId } = sourcePassenger;
    const { passengerfieldid: destinationPassengerFieldId, sequence = 0 } =
      destinationPassenger;

    try {
      setIsSubmitting(true);
      const { data, error } = await updatePassengerFieldSequence(
        sourcePassengerFieldId,
        { sequence }
      );

      if (!data || error) {
        ErrorToast(t('errors:passengerFieldSequenceCouldNotBeUpdated'));

        revertPassengerFieldOrder(
          sourcePassengerFieldId,
          destinationPassengerFieldId
        );
      } else {
        await fetchPassengerFields();
      }
    } catch (error) {
      ErrorToast(t('errors:passengerFieldSequenceCouldNotBeUpdated'));
      revertPassengerFieldOrder(
        sourcePassengerFieldId,
        destinationPassengerFieldId
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFieldStatusChange = async (fieldObject, value) => {
    try {
      const { passengerfieldid } = fieldObject;

      const newPassengerFields = [...passengerFields];
      const index = newPassengerFields.findIndex(
        (field) => field?.passengerfieldid === passengerfieldid
      );

      if (index !== -1) {
        const updatedField = {
          ...newPassengerFields[index],
          isrequired: value === REQUIRED,
          isoptional: value === OPTIONAL,
          ishidden: value === HIDDEN
        };
        newPassengerFields[index] = updatedField;
        setPassengerFields(newPassengerFields);

        setIsSubmitting(true);

        const { data, error } = await updatePassengerFieldStatus(
          passengerfieldid,
          {
            isrequired: updatedField.isrequired,
            isoptional: updatedField.isoptional,
            ishidden: updatedField.ishidden
          }
        );

        if (!data || error) {
          ErrorToast(t('errors:passengerFieldStatusCouldNotBeUpdated'));
          revertPassengerFieldStatus(fieldObject);
        }
      }
    } catch (error) {
      ErrorToast(t('errors:passengerFieldStatusCouldNotBeUpdated'));
      revertPassengerFieldStatus(fieldObject);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchPassengerFields();
    fetchFieldTypes();
  }, []);

  return (
    <Box
      id={id}
      className='trip__passenger-fields-panel'
      data-testid='global-passenger-fields-panel'
    >
      {isLoading ? (
        <PassengersFieldSkeleton id='passenger-field-skeleton' />
      ) : (
        <>
          <Box style={{ paddingBottom: '24px' }}>
            <XGButton
              autoFocus
              id='button-add-amenity'
              text={t('addCustomField')}
              type='primary'
              size='large'
              variant='contained'
              onClick={onOpenModal}
              startIcon={<PlusCircle />}
            />
          </Box>
          <PassengerFieldsTable
            passengerFields={passengerFields}
            handleOnEditField={handleOnEditField}
            handleOnDeleteField={handleOnDeleteField}
            handleUpdateSequence={handleUpdateSequence}
            handleUpdateStatus={handleFieldStatusChange}
            isDropDisabled={isSubmitting}
          />
          <PassengerFieldModal
            fieldTypes={fieldTypes}
            fieldForm={fieldForm}
            setFieldForm={setFieldForm}
            isOpen={isOpenModal}
            isSubmitting={isSubmitting}
            isAddAnswerDisabled={isAddAnswerDisabled}
            setIsAddAnswerDisabled={setIsAddAnswerDisabled}
            onCancel={onCloseModal}
            onSubmit={onSavePassengerField}
          />

          <DeleteModal
            id='delete-passenger-field-component'
            isOpenModal={isDeleteModalOpen}
            title={t('deletePassengerField')}
            onConfirm={handleDeletePassengerFieldConfirmation}
            onCancel={() => {
              setIsDeleteModalOpen(false);
            }}
            description={`${t('passengerFieldDeletionConfirmation')} ${t(
              'common:thisActionCannotBeUndone'
            )}`}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </Box>
  );
};

PassengerFieldsPanel.propTypes = {
  id: PropTypes.string
};

export default PassengerFieldsPanel;
