// @ts-nocheck
import InputAdornment from '@mui/material/InputAdornment';
import { Box } from '@mui/material';
import { XGSelect } from '@xg-cl/xg-cl';
import { useDispatch } from 'react-redux';
import Editor from '../../../Editor/Editor';
import { setSelectedQuantity } from '../../../../redux/slices/booking/booking';
import {
  getBookingPlanDropdownOptions,
  getPlanTotal
} from '../../../../utils/booking';
import { formatCurrency } from '../../../../utils/currencyHelper';
import { User } from '@phosphor-icons/react';
import * as PropTypes from 'prop-types';
import './BookingPlanCard.css';

/**
 * Functional React component for rendering a custom booking plan card.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Object} [props.data] - The data for the booking plan card.
 * @param {string} [props.id] - The ID for the delete booking plan card component.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the booking plan card component.
 */

const BookingPlanCard = ({ data, id, ...rest }) => {
  const { title, selectedQuantity, tripplanid, description } = data;
  const dispatch = useDispatch();
  const selectOptions = getBookingPlanDropdownOptions(data);
  const planTotal = getPlanTotal(data);

  const handleChange = (event, option) => {
    const { value } = option.props;
    dispatch(setSelectedQuantity({ tripplanid, quantity: Number(value) }));
  };

  return (
    <Box
      id={id}
      data-testid='booking-plan-card'
      {...rest}
      className='booking-plan__card'
    >
      <div className='booking-plan__card__quantity'>
        <XGSelect
          name='name'
          size='small'
          onChange={handleChange}
          value={`${selectedQuantity}`}
          menuItems={selectOptions}
          className='booking-plan__card__quantity-select'
          startAdornment={
            <InputAdornment position='start' style={{ marginBottom: '5px' }}>
              <User size={20} fill='#475467' />
            </InputAdornment>
          }
        />
      </div>
      <div className='booking-plan__card__details'>
        <div className='booking-plan__card__details-info'>
          <span className='text-md-semibold'>{title}</span>
          <span className='text-sm-regular plan-description__text'>
            <Editor
              content={description}
              editable={false}
              isPreviewing={true}
              id='trip-output-booking-card-description-editor'
              className='third-output-editor--full-width'
            />
          </span>
        </div>
        <div className='booking-plan__card__details-pricing'>
          <span className='h3-semibold'>{formatCurrency(planTotal)}</span>
        </div>
      </div>
    </Box>
  );
};

BookingPlanCard.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    depositamount: PropTypes.number,
    depositduedate: PropTypes.string,
    lastbookdate: PropTypes.string,
    maxavailable: PropTypes.number,
    occupancy: PropTypes.number,
    priceperperson: PropTypes.number,
    tripplanid: PropTypes.number,
    title: PropTypes.string,
    selectedQuantity: PropTypes.number,
    hideTotalBreakdown: PropTypes.bool
  }),
  id: PropTypes.string.isRequired
};
export default BookingPlanCard;
