// @ts-nocheck
import actionTypes from '../../../constants/actionTypes';
import DaysNavMenu from '../DaysNavMenu/DaysNavMenu';
import DeleteDay from '../DeleteDay/DeleteDay';
import DetailedDay from '../DetailedDay/DetailedDay';
import EditDay from '../EditDay/EditDay';
import { Box, CircularProgress } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Plus } from '@phosphor-icons/react';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { XGButton } from '@xg-cl/xg-cl';
import { addDay, updateSelectedDay } from '../../../redux/slices/trip/trip';
import { createTripDay } from '../../../services/trip/trip';
import { ErrorToast } from '../../../utils/alerts';
import { preventBubbling } from '../../../utils/eventHelper';
import {
  cleanDraggableAction,
  addDraggableAction
} from '../../../redux/slices/library/library';
import { CREATENEWDAY, REORGANIZEDAYS } from '../../../constants/dragAndDrop';
import * as PropTypes from 'prop-types';
import './DetailedView.css';

/**
 * Functional React component for rendering a detailed view of a trip days.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip detailed view component.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip detailed view.
 */

const DetailedView = ({ id, ...rest }) => {
  const { t } = useTranslation(['trip', 'common']);
  const dispatch = useDispatch();
  const storedTripData = useSelector((state) => state.trip);
  const currentUser = useSelector((state) => state.user);
  const libItems = useSelector((state) => state.library?.items ?? []);
  const { tripday: tripdays } = useSelector((state) => state.trip);
  const selectedDay = storedTripData?.tripday.find((day) => day.isSelected);

  //Status definitions
  const [isAddingDay, setIsAddingDay] = useState(false);
  const [opeEditDrawer, setOpeEditDrawer] = useState(false);
  const [editDayItem, setEditDayItem] = useState(null);
  const [deleteDayItem, setDeleteDayItem] = useState(null);
  const [isDeletingDayModalOpen, setIsDeletingDayModalOpen] = useState(null);

  const draggableAction = useSelector(
    (state) => state.library?.itinerary?.draggableAction ?? null
  );

  //Manages the logic when 'Edit" option is clicked from a day tab
  const onEditDay = async (tripDayId) => {
    const dayToEdit = storedTripData.tripday?.find(
      (day) => day.tripdayid === tripDayId
    );

    if (dayToEdit) {
      const { description, title, daynumber, tripdayimage, note } = dayToEdit;

      const cover =
        tripdayimage && tripdayimage.length
          ? tripdayimage[tripdayimage.length - 1]
          : null;

      setEditDayItem({
        dayNumber: daynumber,
        tripId: storedTripData.tripid,
        tripDayId: tripDayId,
        description: description,
        title: title,
        note: note,
        coverImage: cover
      });

      setOpeEditDrawer((prev) => !prev);
      dispatch(updateSelectedDay(tripDayId));
    }
    return;
  };

  //Manages the logic when 'Delete" option is clicked from a day tab
  const onDeleteDay = async (tripDayId) => {
    setDeleteDayItem({
      tripDayId
    });

    dispatch(updateSelectedDay(tripDayId));
    setIsDeletingDayModalOpen(true);
  };

  //Manages the logic when 'Add day" button is clicked
  const handleOnAddDay = async () => {
    try {
      const payload = {
        tripid: storedTripData.tripid,
        email: currentUser.email
      };

      setIsAddingDay(true);

      const result = await createTripDay(payload);

      if (result.error || !result.data) {
        ErrorToast(t('errors:tripDayCouldNotBeCreated'));
      } else {
        dispatch(addDay(result?.data));
      }
      setIsAddingDay(false);
    } catch (err) {
      setIsAddingDay(false);
      ErrorToast(t('errors:tripDayCouldNotBeCreated'));
    }
  };

  //Handles the state of selected day when user clicks on a specific day tab
  const onSelectDay = async (tripDayId) => {
    dispatch(updateSelectedDay(tripDayId));
  };

  //General event handler to manage events from days tabs.
  const handleOnDayAction = async (event, action, tripDayId) => {
    preventBubbling(event);

    switch (action) {
      case actionTypes.EDIT:
        onEditDay(tripDayId);
        break;
      case actionTypes.DELETE:
        onDeleteDay(tripDayId);
        break;

      case actionTypes.SELECT:
        onSelectDay(tripDayId);
        break;
      default:
        break;
    }
  };

  //Creates a new day from library draggable element
  const onCreateDayFromLibray = useCallback(
    async (draggableId = '', destinationIndex = 0) => {
      try {
        setIsAddingDay(true);
        const elementId = draggableId.split('.')[1] ?? '';

        if (elementId) {
          const foundLibItem = libItems.find(
            (item) => item?.contentid === parseInt(elementId)
          );

          if (foundLibItem) {
            const {
              title,
              description,
              note,
              contentimage = []
            } = foundLibItem;
            const payload = {
              tripid: storedTripData.tripid,
              email: currentUser.email,
              title,
              description,
              note,
              images: contentimage
            };

            const result = await createTripDay(payload);

            if (result.error || !result.data) {
              ErrorToast(t('errors:tripDayCouldNotBeCreated'));
            } else {
              dispatch(addDay(result?.data));
              dispatch(cleanDraggableAction());
              dispatch(
                addDraggableAction({
                  context: 'itinerary',
                  data: {
                    type: REORGANIZEDAYS,
                    sourceIndex: tripdays.length,
                    destinationIndex: destinationIndex
                  }
                })
              );
            }
          }
        }
      } catch (err) {
        ErrorToast(t('errors:tripDayCouldNotBeCreated'));
      } finally {
        setIsAddingDay(false);
      }
    },
    [libItems, currentUser, tripdays, dispatch, storedTripData, t]
  );

  useEffect(() => {
    const {
      type = null,
      draggableId = '',
      destinationIndex = 0
    } = draggableAction ?? {};

    if (type === CREATENEWDAY) {
      onCreateDayFromLibray(draggableId, destinationIndex);
    }
  }, [draggableAction, onCreateDayFromLibray]);

  return (
    <>
      <Box
        id={id}
        className='detailed-view-wapper'
        data-testid='trip-detailed-view'
        {...rest}
      >
        <Box id={id} className='detailed-view-nav-menu'>
          <DaysNavMenu
            id='detailed-view-nav-menu'
            onAction={handleOnDayAction}
            selectedDay={selectedDay?.tripdayid}
          />
          <Box
            sx={{
              width: '100%',
              paddingRight: '16px !important',
              position: 'relative'
            }}
          >
            <XGButton
              sx={{ width: '100%' }}
              data-testid='detailed-view-add-day-button'
              id='detailed-view-add-day-button'
              text={t('addDay')}
              type='primary'
              size='large'
              variant='contained'
              className='add-day-button'
              endIcon={
                <Box className='add-day-icon'>
                  <Plus color='#98A2B3' size={16} />
                </Box>
              }
              onClick={handleOnAddDay}
              disabled={isAddingDay}
            />
            {isAddingDay && (
              <CircularProgress
                size={24}
                sx={{
                  color: blue[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
          </Box>
        </Box>

        {selectedDay && (
          <Box id={id} className='detailed-view-day-content'>
            <DetailedDay
              data={{ ...selectedDay, startdate: storedTripData?.startdate }}
              id='detailed-view-day'
            />
          </Box>
        )}
      </Box>

      <EditDay
        data={editDayItem}
        id='detailed-edit-day-component'
        isOpenDrawer={opeEditDrawer}
        onToggle={(isOpen) => {
          setOpeEditDrawer(isOpen);
        }}
      />

      {deleteDayItem && isDeletingDayModalOpen && (
        <DeleteDay
          data={deleteDayItem}
          id='delete-day-service-component'
          isOpenModal={isDeletingDayModalOpen}
          updateIsOpen={(isOpenModal) => {
            setIsDeletingDayModalOpen(isOpenModal);
          }}
        />
      )}
    </>
  );
};

DetailedView.propTypes = {
  id: PropTypes.string.isRequired
};

export default DetailedView;
