//@ts-nocheck
import request from '../request';

export const createTrip = async (data = {}) => {
  const { outline, ...otherData } = data;

  const endpoint = outline && outline.trim() ? 'trips/aitripbuilder' : 'trips';
  const payload =
    outline && outline.trim()
      ? { ...otherData, itinerarytext: outline }
      : otherData;

  return request.post(endpoint, payload).then((res) => res);
};

export const createTripDay = (data = {}) =>
  request.post('trips/day', data).then((res) => res);

export const createTripContent = (data = {}) =>
  request.post('trips/content', data).then((res) => res);

export const getTrip = (tripId = '') => {
  return request.get(`trips/${tripId}`).then((res) => res);
};

export const addServiceToTripDay = (data = {}) =>
  request.post('trips/lineitem', data).then((res) => res);

export const updateTripDay = (tripDayId = '', data = {}) =>
  request.put(`trips/day/${tripDayId}`, data).then((res) => res);

export const updateTripContent = (tripContentId = '', data = {}) =>
  request.put(`trips/content/${tripContentId}`, data).then((res) => res);

export const updateTripDayService = (lineItemId = '', data = {}) =>
  request.put(`lineitems/${lineItemId}`, data).then((res) => res);

export const updateTripDaysSequence = (data = {}) =>
  request.put(`trips/days/sequence`, data).then((res) => res);

export const updateTripContentItemSequence = (data = {}) =>
  request.put(`trips/content/sequence`, data).then((res) => res);

export const updateLineItemsSequence = (data = {}) =>
  request.put(`lineitems/sequence`, data).then((res) => res);

export const updateTripStatus = (tripId = '', data = {}) =>
  request.put(`trips/${tripId}/status`, data).then((res) => res);

export const updateTripHeader = (tripId = '', data = {}) =>
  request.put(`trips/${tripId}`, data).then((res) => res);

export const deleteTripDay = (tripDayId = '', data = {}) =>
  request.delete(`trips/day/${tripDayId}`, data).then((res) => res);

export const deleteTripContent = (tripContentId = '', data = {}) =>
  request.delete(`trips/content/${tripContentId}`, data).then((res) => res);

export const deleteTripDayService = (lineItemId = '', data = {}) =>
  request.delete(`lineitems/${lineItemId}`, data).then((res) => res);

export const addTripImage = (tripId = '', data = {}) =>
  request.put(`trips/${tripId}/image`, data).then((res) => res);

export const addTripDayImage = (tripDayId = '', data = {}) =>
  request.put(`trips/day/${tripDayId}/image`, data).then((res) => res);

export const addTripContentImage = (tripContentId = '', data = {}) =>
  request.put(`trips/content/${tripContentId}/image`, data).then((res) => res);

export const addTripDayItemImage = (lineItemId = '', data = {}) =>
  request.put(`lineitems/${lineItemId}/image`, data).then((res) => res);

export const updateTripVisibility = (tripId = '', data = {}) =>
  request.put(`trips/${tripId}`, data).then((res) => res);

export const cloneTrip = (tripid = '', data = {}) =>
  request.post(`trip/${tripid}/clone`, data).then((res) => res);

export const createTripPricingPlan = (data = {}) =>
  request.post('tripplans', data).then((res) => res);

export const deleteTripPricingPlan = (tripplanid = '', data = {}) =>
  request.delete(`tripplans/${tripplanid}`, data).then((res) => res);

export const updateTripPricingPlan = (tripplanid = '', data = {}) =>
  request.put(`tripplans/${tripplanid}`, data).then((res) => res);

export const getTripPricingPlan = (tripid = '', data = {}) =>
  request.get(`tripplans/${tripid}`, data).then((res) => res);

export const updateTripFees = (tripid = '', data = {}) =>
  request.put(`trips/${tripid}/fee`, data).then((res) => res);

export const getTripAmenity = (tripid = '') =>
  request.get(`trips/${tripid}/amenity`).then((res) => res);

export const createTripAmenity = (data = {}) =>
  request.post('trips/amenity', data).then((res) => res);

export const updateTripAmenity = (tripamenityid = '', data = {}) =>
  request.put(`trips/amenity/${tripamenityid}`, data).then((res) => res);

export const deleteTripAmenity = (tripamenityid = '') =>
  request.delete(`trips/amenity/${tripamenityid}`).then((res) => res);

export const deleteTrip = (tripId = '') => request.delete(`trips/${tripId}`);

export const getTripPassengerFields = (tripid = '') =>
  request.get(`trips/${tripid}/passengerfields`).then((res) => res);

export const createTripPassengerField = (tripid = '', data = {}) =>
  request.post(`trips/${tripid}/passengerfields`, data).then((res) => res);

export const updateTripPassengerField = (
  tripid = '',
  trippassengerfieldid = '',
  data = {}
) =>
  request
    .put(`trips/${tripid}/passengerfields/${trippassengerfieldid}`, data)
    .then((res) => res);

export const deleteTripPassengerField = (
  tripid = '',
  trippassengerfieldid = ''
) =>
  request
    .delete(`trips/${tripid}/passengerfields/${trippassengerfieldid}`)
    .then((res) => res);

export const updateTripPassengerFieldSequence = (
  tripid = '',
  trippassengerfieldid = '',
  data = {}
) =>
  request
    .put(
      `trips/${tripid}/passengerfields/${trippassengerfieldid}/sequence`,
      data
    )
    .then((res) => res);

export const updateTripPassengerFieldStatus = (
  tripid = '',
  trippassengerfieldid = '',
  data = {}
) =>
  request
    .put(`trips/${tripid}/passengerfields/${trippassengerfieldid}/status`, data)
    .then((res) => res);
