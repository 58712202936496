// @ts-nocheck
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { XGButton } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import './BookingPassengerActions.css';

/**
 * Render actions for booking passengers component
 *
 * @namespace Components
 *
 * @param {boolean} isSubmitting - Flag indicating if form is currently submitting
 * @param {function} onBack - Callback function for cancel action
 * @param {function} onNext - Callback function for submit action
 * @param {boolean} disabledConfirm - Flag indicating if confirm button should be disabled
 * @return {JSX.Element} The booking passenger actions component
 */
const BookingPassengerActions = ({
  isSubmitting,
  onBack,
  onNext,
  disabledConfirm
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Box className='booking-modal__actions' data-testid='booking-modal-actions'>
      <Box sx={{ position: 'relative' }}>
        <XGButton
          data-testid='action-passengers-back-button'
          id='action-passengers-back-button'
          text={t('back')}
          type='secondary'
          size='medium'
          variant='outlined'
          onClick={onBack}
        />
      </Box>

      <Box sx={{ position: 'relative' }}>
        <XGButton
          data-testid='action-passengers-next-button'
          id='action-passengers-next-button'
          text={t('next')}
          type='primary'
          size='medium'
          variant='contained'
          onClick={onNext}
          disabled={disabledConfirm}
        />

        {isSubmitting && (
          <CircularProgress
            id='booking-modal-actions-loader'
            size={24}
            sx={{
              color: blue[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        )}
      </Box>
    </Box>
  );
};

BookingPassengerActions.propTypes = {
  isSubmitting: PropTypes.bool,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  disabledConfirm: PropTypes.bool
};

export default BookingPassengerActions;
