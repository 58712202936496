import { useEffect } from 'react';

/**
 * Custom hook to handle clicks outside specified elements.
 *
 * @param {Array<React.RefObject<HTMLElement>>} refs - Array of refs to detect clicks inside.
 * @param {Function} onClickOutside - Callback function to execute on outside click.
 */
const useClickOutside = (refs, onClickOutside) => {
  useEffect(() => {
    /**
     * Handles the mousedown event.
     *
     * @param {MouseEvent} event - The mouse event.
     */
    const handleClickOutside = (event) => {
      const clickedInside = refs.some((ref) => {
        if (!ref.current) return false;
        const target = event.target instanceof Node ? event.target : null;

        return ref.current.contains(target);
      });

      if (!clickedInside) {
        onClickOutside(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, onClickOutside]);
};

export default useClickOutside;
