// @ts-nocheck
import IconButton from '@mui/material/IconButton';
import PassengerFieldModalActions from '../TripPassengerFieldModalActions/TripPassengerFieldModalActions';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { XGButton, XGModal, XGSelect, XGInput, XGTextarea } from '@xg-cl/xg-cl';
import { PlusCircle, Trash } from '@phosphor-icons/react';
import * as PropTypes from 'prop-types';
import './TripPassengerFieldModal.css';

/**
 * Render the trip passenger field modal
 *
 * @param {function} onCancel - Callback function for cancel action
 * @param {function} onSubmit - Callback function for submit action
 * @param {boolean} isSubmitting - Flag indicating if form is currently submitting
 * @param {boolean} isOpen - Flag indicating if modal is currently open
 * @param {boolean} fieldTypes - List of field types
 * @param {object} fieldForms - Field form data
 * @param {function} setFieldForm - Callback function for setting field form
 * @return {JSX.Element} The update actions component
 */
const TripPassengerFieldModal = ({
  onCancel,
  onSubmit,
  isSubmitting,
  isAddAnswerDisabled,
  isOpen,
  fieldTypes = [],
  fieldForm = null,
  setFieldForm,
  setIsAddAnswerDisabled
}) => {
  const { t } = useTranslation(['passengers', 'common']);
  const {
    trippassengerfieldoption = [],
    selectedFieldType,
    isEditing
  } = fieldForm;

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    let newFieldOptions = [];

    if (name === 'fieldType') {
      const foundFieldType = fieldTypes.find(
        (fieldType) => fieldType?.label === value
      );

      const { allowoptions, key } = foundFieldType;

      if (allowoptions) {
        //Boolean should only have 2 options
        if (key === 'boolean') {
          newFieldOptions =
            trippassengerfieldoption?.length > 2
              ? [trippassengerfieldoption[0], trippassengerfieldoption[1]]
              : trippassengerfieldoption;

          setIsAddAnswerDisabled(trippassengerfieldoption?.length >= 2);
        } else {
          setIsAddAnswerDisabled(false);
          newFieldOptions = trippassengerfieldoption;
        }
      }

      setFieldForm((prev) => ({
        ...prev,
        selectedFieldType: foundFieldType,
        trippassengerfieldoption: newFieldOptions,
        error: { ...prev.error, selectedFieldType: '' }
      }));
    } else {
      setFieldForm((prev) => ({
        ...prev,
        [name]: value,
        error: { ...prev.error, [name]: '' }
      }));
    }
  };

  const handleOptionChange = (evt, index) => {
    const { name, value } = evt.target;
    const newOptions = [...trippassengerfieldoption];

    newOptions[index][name] = value;
    newOptions[index].error = '';

    setFieldForm((prev) => ({
      ...prev,
      trippassengerfieldoption: newOptions
    }));
  };

  const handleAddOption = () => {
    const newOptions = [
      ...trippassengerfieldoption,
      {
        title: '',
        sequence: trippassengerfieldoption.length + 1,
        isselected: false
      }
    ];
    const shouldAddAnswer =
      selectedFieldType?.key === 'boolean' &&
      trippassengerfieldoption.length >= 2
        ? false
        : true;

    if (shouldAddAnswer) {
      setFieldForm((prev) => ({
        ...prev,
        trippassengerfieldoption: newOptions,
        error: { ...prev.error, fieldOptions: '' }
      }));
    }

    if (selectedFieldType?.key === 'boolean' && newOptions.length === 2) {
      setIsAddAnswerDisabled(true);
    }
  };

  const handleRemoveOption = (sequence = 0) => {
    const newOptions = [...trippassengerfieldoption]
      ?.filter((option) => option.sequence !== sequence)
      ?.map((option, index) => {
        return {
          ...option,
          sequence: index + 1
        };
      });

    setFieldForm((prev) => ({
      ...prev,
      trippassengerfieldoption: newOptions
    }));

    if (selectedFieldType?.key === 'boolean' && newOptions.length < 2) {
      setIsAddAnswerDisabled(false);
    }
  };

  return (
    <Box data-testid='trip-passenger-field-modal'>
      <XGModal
        id='trip_passengers-fields-modal'
        allowClosing={true}
        data-testid={'trip-passengers-fields-modal'}
        actions={
          <PassengerFieldModalActions
            onCancel={onCancel}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        }
        dismissable={false}
        open={isOpen}
        title={`${isEditing ? t('editPassengerField') : t('newPassengerField')}`}
        labelledby={`${isEditing ? t('editPassengerField') : t('newPassengerField')}`}
        maxWidth='lg'
        scroll='paper' //body
        onClose={onCancel}
      >
        <Box className='trip__passenger-fields__modal-container'>
          <Box className='w-100' style={{ display: 'flex', gap: '16px' }}>
            <Box className='w-40'>
              <XGSelect
                id='trip-passenger-field-field-type-select'
                label={`${t('fieldType')}`}
                name='fieldType'
                size='small'
                value={selectedFieldType?.label ?? ''}
                menuItems={fieldTypes ?? []}
                onChange={handleChange}
                error={
                  Object.hasOwn(fieldForm?.error ?? {}, 'selectedFieldType') &&
                  fieldForm?.error?.selectedFieldType !== ''
                }
              />
              {Object.hasOwn(fieldForm?.error ?? {}, 'selectedFieldType') &&
                fieldForm?.error?.selectedFieldType !== '' && (
                  <span className='text-sm-regular error-text'>
                    {fieldForm?.error?.selectedFieldType}
                  </span>
                )}
            </Box>
            <Box className='w-60 passenger-field__form-group'>
              <XGInput
                id='passenger-field-title-input'
                label={`${t('fieldTitle')}`}
                name='title'
                placeholder={t('enterFieldTitle')}
                type='text'
                value={fieldForm?.title ?? ''}
                onChange={handleChange}
                inputProps={{
                  maxLength: 100
                }}
                error={
                  Object.hasOwn(fieldForm?.error ?? {}, 'title') &&
                  fieldForm?.error?.title !== ''
                }
                hint={fieldForm?.error?.title}
              />
              <span className='title-length-hint length-hint text-sm-medium'>
                {fieldForm?.title?.length}/100
              </span>
            </Box>
          </Box>

          <Box className='w-100 trip__passenger-fields__form-group'>
            <XGTextarea
              id='trip-passenger-field-title-input-decription'
              label={t('fieldDescription')}
              placeholder={t('enterFieldDescription')}
              value={fieldForm?.description ?? ''}
              minRows={3}
              maxRows={4}
              inputProps={{
                maxLength: 500
              }}
              name='description'
              sx={{
                width: '100%',
                height: 'auto',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
              onChange={handleChange}
            />
            <span
              className='title-length-hint length-hint text-sm-medium'
              style={{ marginTop: '58px' }}
            >
              {fieldForm?.description?.length}/500
            </span>
          </Box>

          {selectedFieldType?.allowoptions &&
            trippassengerfieldoption &&
            trippassengerfieldoption.length > 0 && (
              <Box className='w-100 trip__passenger-fields__options-container'>
                {trippassengerfieldoption.map((option, index) => {
                  return (
                    <Box
                      className='w-100 trip__passenger-fields__option-row'
                      key={index}
                    >
                      <Box className='w-55' style={{ position: 'relative' }}>
                        <XGInput
                          id={`trip-passenger-field-option-input-${index}`}
                          label={`${t('answer')} #${option?.sequence ?? index}`}
                          name='title'
                          placeholder={t('enterAnswerTitle')}
                          type='text'
                          value={option?.title ?? ''}
                          onChange={(evt) => handleOptionChange(evt, index)}
                          inputProps={{
                            maxLength: 100
                          }}
                          error={
                            Object.hasOwn(option ?? {}, 'error') &&
                            option?.error !== ''
                          }
                          hint={option?.error}
                        />
                      </Box>
                      <Box style={{ marginLeft: '24px', position: 'relative' }}>
                        <IconButton
                          aria-label='Delete'
                          onClick={() => handleRemoveOption(option?.sequence)}
                          disabled={isSubmitting}
                        >
                          <Trash size={24} color='#1361D8' />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}

          {selectedFieldType?.allowoptions && (
            <Box
              style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
            >
              <XGButton
                className={'trip__passenger-fields__add-answer-button'}
                id='trip-passenger-field-add-answer-button'
                data-testid='trip-passenger-field-add-answer-button'
                text={t('addAnswer')}
                type='primary'
                size='medium'
                variant='outlined'
                onClick={handleAddOption}
                disabled={isSubmitting || isAddAnswerDisabled}
                startIcon={
                  <PlusCircle
                    className={`trip__passenger-fields__action-icon ${isAddAnswerDisabled ? 'trip__passenger-fields__action-icon__disabled' : ''}`}
                  />
                }
              />
              {Object.hasOwn(fieldForm?.error ?? {}, 'fieldOptions') &&
                fieldForm?.error?.fieldOptions !== '' && (
                  <span className='text-sm-regular error-text'>
                    {fieldForm?.error?.fieldOptions}
                  </span>
                )}
            </Box>
          )}
        </Box>
      </XGModal>
    </Box>
  );
};

TripPassengerFieldModal.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isOpen: PropTypes.bool,
  isAddAnswerDisabled: PropTypes.bool,
  setIsAddAnswerDisabled: PropTypes.func,
  fieldTypes: PropTypes.array,
  fieldForm: PropTypes.object,
  setFieldForm: PropTypes.func
};

export default TripPassengerFieldModal;
