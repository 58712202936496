//@ts-nocheck

/**
 * Sorts the given list of objects by the specified property in the specified order.
 *
 * @param {Array} list - The list of objects to be sorted.
 * @param {string} property - The property by which the list should be sorted.
 * @param {string} [order='asc'] - The order in which the list should be sorted, defaults to 'asc'.
 * @return {Array} - The sorted list of objects.
 */
export const sortByProperty = (list = [], property, order = 'asc') => {
  if (!Array.isArray(list)) {
    return [];
  }

  return list.sort((a, b) => {
    if (order === 'desc') {
      return b[property] - a[property];
    } else {
      return a[property] - b[property];
    }
  });
};

/**
 * Returns the last item of the given list.
 *
 * @param {Array} list - The input list
 * @return {*} The last item of the list, or null if the list is empty or not an array
 */
export const getLastListItem = (list = []) => {
  if (!Array.isArray(list) || list.length === 0) {
    return null;
  }

  return list[list.length - 1];
};

/**
 * Returns a reordered list.
 *
 * @param {Array} list - The input list
 * @param {number} startIndex - The start index of the list
 * @param {number} endIndex - The end index of the list
 * @return {Array} The reordered list
 */
export const reorderItems = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Remove accents and special characters and generate an object key
 *
 * @param {string} text - The input text
 * @return {string} The generated key
 */
export const generateKey = (text = '') => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .toLowerCase()
    .replace(/\s+/g, '');
};
