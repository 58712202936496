// @ts-nocheck
import IconButton from '@mui/material/IconButton';
import { XGRadio } from '@xg-cl/xg-cl';
import { DotsSixVertical, Trash, PencilSimple } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import {
  REQUIRED,
  OPTIONAL,
  HIDDEN
} from '../../../../constants/passengerFieldStatuses';
import '../TripPassengersFieldsTable/TripPassengersFieldsTable.css';
import * as PropTypes from 'prop-types';

/**
 * Render the trip passengers field table row
 *
 * @param {object} field - Field data object
 * @param {function} updatedField - Callback function for update action
 * @param {function} handleDelete - Callback function for delete action
 * @param {function} handleEdit - Callback function for edit action
 * @return {JSX.Element} The passengers field table row component
 */
const TripPassengersFieldsRow = ({
  field,
  updatedFieldStatus,
  handleDelete,
  handleEdit
}) => {
  const { isoptional, isrequired, ishidden } = field;
  const { t } = useTranslation(['passengers', 'common']);

  const handleChangeSelect = (event) => {
    updatedFieldStatus(field, event?.target?.value);
  };

  return (
    <table className='passengersfields_table'>
      <tbody>
        <tr>
          <td>
            <DotsSixVertical
              className='trip__passengersfields__icon'
              color='#344054'
              size={22}
            />
            {field?.title || ''}
          </td>
          <td>
            <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
              <XGRadio
                id={`passenger-field-option-required-${field?.trippassengerfieldid}`}
                name='gender'
                label={t('common:required')}
                size='small'
                value={REQUIRED}
                checked={isrequired}
                onChange={handleChangeSelect}
              />
              <XGRadio
                id={`passenger-field-option-optional-${field?.trippassengerfieldid}`}
                name='gender'
                label={t('common:optional')}
                size='small'
                value={OPTIONAL}
                checked={isoptional}
                onChange={handleChangeSelect}
              />
              <XGRadio
                id={`passenger-field-option-hidden-${field?.trippassengerfieldid}`}
                name='gender'
                label={t('common:hidden')}
                size='small'
                value={HIDDEN}
                checked={ishidden}
                onChange={handleChangeSelect}
              />
              <div style={{ marginLeft: '24px', display: 'flex' }}>
                <IconButton
                  aria-label={t('common:delete')}
                  onClick={() => {
                    handleDelete(field?.trippassengerfieldid);
                  }}
                >
                  <Trash size={24} color='#1361D8' />
                </IconButton>
                <IconButton
                  aria-label={t('common:edit')}
                  onClick={() => {
                    handleEdit(field?.trippassengerfieldid);
                  }}
                >
                  <PencilSimple size={24} color='#1361D8' />
                </IconButton>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

TripPassengersFieldsRow.propTypes = {
  field: PropTypes.object,
  updatedFieldStatus: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func
};

export default TripPassengersFieldsRow;
