// @ts-nocheck
import { useState, useRef, useEffect, useCallback } from 'react';
import { Switch } from '@mui/material';
import { XGTextarea, XGInput } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { ErrorToast } from '../../../utils/alerts';
import {
  createTripAmenity,
  updateTripAmenity
} from '../../../services/trip/trip';
import useEscapeKey from '../../../hooks/useEscapeKey';
import useClickOutside from '../../../hooks/useClickOutside';
/**
 * Default state for a new amenity.
 */
const defaultAmenityState = {
  tripamenityid: null,
  name: '',
  description: '',
  include: true,
  exclude: false
};

/**
 * Component for adding a new amenity row.
 *
 *
 * @namespace Components
 *
 * @param {Object} props - The component props.
 * @param {number} props.tripid - The ID of the trip associated with the amenity.
 * @param {boolean} props.isVisible - Determines if the row is visible.
 * @param {Function} props.setAmenitiesList - Function to update the list of amenities.
 * @param {Function} props.toggleVisibility - Function to toggle the visibility of the row.
 *
 * @returns {JSX.Element|null} A JSX element representing the amenity row or null if not visible.
 */
const AddAmenityRow = ({
  isVisible,
  setAmenitiesList,
  tripid,
  toggleVisibility
}) => {
  const { t } = useTranslation(['trip', 'common']);
  const componentRef = useRef(null);
  const debounceTimeAddAmenity = 1000; // 1 second
  const debounceTimeoutRefAddAmenity = useRef(null); // Ref to track timeout
  const [newAmenity, setNewAmenity] = useState(defaultAmenityState);

  /**
   * Handles changes to the amenity input fields.
   *
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The change event.
   */
  const handleNewAmenityChange = (e) => {
    const { name, value, checked } = e.target;
    const isCheckedValue = ['include', 'exclude'].includes(name);
    const propValue = isCheckedValue ? checked : value;
    const newValues = {
      include: name === 'exclude' ? false : newAmenity.include,
      exclude: name === 'include' ? false : newAmenity.exclude,
      [name]: propValue
    };
    setNewAmenity((prev) => ({ ...prev, ...newValues }));
  };

  /**
   * Handles the debounced creation or update of an amenity.
   */
  const handleDebouncedAmenityAction = useCallback(async () => {
    const isIncluded = newAmenity.exclude ? false : newAmenity.include;

    try {
      if (newAmenity.tripamenityid) {
        // Update existing amenity
        await updateTripAmenity(newAmenity.tripamenityid, {
          tripid,
          name: newAmenity.name,
          description: newAmenity.description,
          isincluded: isIncluded
        });
      } else {
        // Create new amenity
        const result = await createTripAmenity({
          tripid,
          name: newAmenity.name,
          description: newAmenity.description,
          isincluded: isIncluded
        });
        toggleVisibility(false);
        setAmenitiesList((prev) => [
          {
            ...newAmenity,
            tripamenityid: result.data.tripamenityid
          },
          ...prev
        ]);
        setNewAmenity(defaultAmenityState);
      }
    } catch (error) {
      ErrorToast(t('errors:amenityCouldNotBeSaved', { reason: error.message }));
    }
  }, [
    newAmenity,
    setNewAmenity,
    toggleVisibility,
    setAmenitiesList,
    tripid,
    t
  ]);

  const hideForm = () => {
    setNewAmenity(defaultAmenityState);
    toggleVisibility(false);
  };

  useEffect(() => {
    async function perform() {
      const currentAmenityValues = { ...newAmenity };
      const hasMissingData = !currentAmenityValues.name;

      // Prevent any action if there is missing data
      if (hasMissingData) return;

      // Reset and start debounce timer
      if (debounceTimeoutRefAddAmenity.current) {
        clearTimeout(debounceTimeoutRefAddAmenity.current);
      }

      debounceTimeoutRefAddAmenity.current = setTimeout(async () => {
        await handleDebouncedAmenityAction();
      }, debounceTimeAddAmenity);
    }

    perform();

    // Cleanup timeout when the component is unmounted or when newAmenity changes
    return () => {
      if (debounceTimeoutRefAddAmenity.current) {
        clearTimeout(debounceTimeoutRefAddAmenity.current);
      }
    };
  }, [newAmenity, handleDebouncedAmenityAction]);

  // Hides form and reset state if user hits escape key
  useEscapeKey(hideForm);
  // Hides form and reset state if user clicks outside
  useClickOutside([componentRef], hideForm);

  if (!isVisible) return null;

  return (
    <tr ref={componentRef}>
      <td>
        <XGInput
          id='add-amenity-name-input'
          name='name'
          placeholder={`${t('common:enter')} name`}
          value={newAmenity.name}
          onChange={handleNewAmenityChange}
          className={'amenities-add-amenity-form__name'}
          autoFocus
          type='text'
          inputProps={{
            maxLength: 100
          }}
        />
      </td>
      <td>
        <div className='amenities-description__row '>
          <div className='amenities-description__row-input'>
            <XGTextarea
              id='add-amenity-description-input'
              name='description'
              placeholder={`${t('common:enter')} description`}
              value={newAmenity.description}
              onChange={handleNewAmenityChange}
              inputProps={{
                maxLength: 200
              }}
              minRows={2}
              maxRows={2}
              fullWidth
            />
          </div>
          <div className='amenities-description__row-label'>
            <span className='text-sm-medium'>
              {newAmenity.description?.length || 0}
              /200
            </span>
          </div>
        </div>
      </td>
      <td>
        <Switch
          id='add-amenity-include-checkbox'
          name='include'
          checked={newAmenity.include}
          onChange={handleNewAmenityChange}
          color='primary'
        />
      </td>
      <td>
        <Switch
          id='add-amenity-exclude-checkbox'
          name='exclude'
          checked={newAmenity.exclude}
          onChange={handleNewAmenityChange}
          color='primary'
        />
      </td>
    </tr>
  );
};

AddAmenityRow.propTypes = {
  tripid: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setAmenitiesList: PropTypes.func.isRequired,
  toggleVisibility: PropTypes.func.isRequired
};

export default AddAmenityRow;
