// @ts-nocheck
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { Box } from '@mui/material';
import { stripeAppearance } from '../../../../constants/stripe';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a custom booking payment component .
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the booking payment component.
 * @param {Object} [props.stripePromise] - The Promise object for Stripe.
 * @param {string} [props.clientSecret] - The client secret for Stripe.
 * @param {string} [props.onNavigateToPassengers] - The client secret for Stripe.
 *
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the booking payment component.
 */

const BookingPayment = ({
  id,
  stripePromise,
  clientSecret,
  paymentIntentId,
  paymentMethodOrder,
  onNavigateToPassengers,
  ...rest
}) => {
  return (
    <>
      {stripePromise && clientSecret && (
        <Box
          id={id}
          data-testid='booking-payment'
          {...rest}
          className='book-now-modal__payment-options'
        >
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
              appearance: stripeAppearance
            }}
          >
            <CheckoutForm
              id='booking-checkout-form'
              paymentIntentId={paymentIntentId}
              paymentMethodOrder={paymentMethodOrder}
              onNavigateToPassengers={onNavigateToPassengers}
            />
          </Elements>
        </Box>
      )}
    </>
  );
};

BookingPayment.propTypes = {
  clientSecret: PropTypes.string,
  id: PropTypes.string.isRequired,
  stripePromise: PropTypes.object,
  paymentIntentId: PropTypes.string,
  paymentMethodOrder: PropTypes.array,
  onNavigateToPassengers: PropTypes.func
};

export default BookingPayment;
