// @ts-nocheck
import PassengersFieldsRow from '../PassengersFieldsRow/PassengersFieldsRow';
import DraggableList from '../../../Draggable/DraggableList/DraggableList';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { reorderItems } from '../../../../utils/common';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import './PassengersFieldsTable.css';

/**
 * Render the passenger fields table component.
 *
 * @param {Array} passengerFields - The list of passenger fields
 * @return {JSX.Element} The passenger fields table component
 */
const PassengerFieldsTable = ({
  passengerFields = [],
  handleOnEditField,
  handleOnDeleteField,
  handleUpdateSequence,
  handleUpdateStatus,
  isDropDisabled = false
}) => {
  const { t } = useTranslation(['settings', 'common']);
  const [isMounted, setIsMounted] = useState(false);
  const [mandatoryPassengersFields, setMandatoryPassengersFields] = useState(
    []
  );
  const [optionalPassengerFields, setOptionalPassengerFields] = useState([]);
  const [travelerFields, setTravelerFields] = useState([]);

  //Handles actions when an element is dropped
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const foundSource = travelerFields[result.source.index];
    const foundDestination = travelerFields[result.destination.index];

    const reorderedFields = reorderItems(
      optionalPassengerFields,
      result.source.index,
      result.destination.index
    );

    setOptionalPassengerFields(reorderedFields);

    handleUpdateSequence(foundSource, foundDestination);
  };

  const getFieldLabel = (field) => {
    if (field?.isrequired) {
      return t('common:required');
    } else if (field?.isoptional) {
      return t('common:optional');
    } else {
      return t('common:hidden');
    }
  };

  //On react 18 we need to load Droppable element after the component is mounted otherwise it won't work
  //See: https://github.com/atlassian/react-beautiful-dnd/issues/2399
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const setupTravelerFields = () => {
      if (
        optionalPassengerFields &&
        Object.keys(optionalPassengerFields).length > 0
      ) {
        const travelerFieldsList = [];
        optionalPassengerFields.map((field, index) => {
          const newField = {
            id: index,
            passengerfieldid: field?.passengerfieldid,
            sequence: field?.sequence || index + 1,
            content: (
              <PassengersFieldsRow
                field={field}
                updatedFieldStatus={handleUpdateStatus}
                handleDelete={handleOnDeleteField}
                handleEdit={handleOnEditField}
              />
            )
          };
          travelerFieldsList.push(newField);
        });
        setTravelerFields(travelerFieldsList);
      }
    };

    setupTravelerFields();
    // eslint-disable-next-line
  }, [optionalPassengerFields]);

  useEffect(() => {
    const setupPassengerFields = () => {
      if (!passengerFields?.length) {
        setMandatoryPassengersFields([]);
        setOptionalPassengerFields([]);
        return;
      } else {
        const systemFields = passengerFields
          ?.filter((field) => {
            return field?.issystemfield;
          })
          .sort((a, b) => a?.sequence - b?.sequence);

        const customFields = passengerFields
          ?.filter((field) => {
            return !field?.issystemfield;
          })
          .sort((a, b) => a?.sequence - b?.sequence);

        setMandatoryPassengersFields(systemFields);
        setOptionalPassengerFields(customFields);
      }
    };

    setupPassengerFields();
  }, [passengerFields]);

  return (
    <Box className='passengersfields_box'>
      <table className='passengersfields_table'>
        <thead>
          <tr>
            <th>{t('settings:field')}</th>
            <th>{t('settings:display')}</th>
          </tr>
        </thead>
        <tbody>
          {mandatoryPassengersFields.map((field, index) => (
            <tr key={index}>
              <td>{field?.title}</td>
              <td className='text-uppercase '>{getFieldLabel(field)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <DragDropContext onDragEnd={onDragEnd}>
        {isMounted && travelerFields.length > 0 && travelerFields && (
          <Droppable
            droppableId='passengers-field-droppablex'
            isDropDisabled={isDropDisabled}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='passengers-fields-drag-and-drop'
              >
                <DraggableList items={travelerFields} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </Box>
  );
};

PassengerFieldsTable.propTypes = {
  passengerFields: PropTypes.array,
  handleOnEditField: PropTypes.func,
  handleOnDeleteField: PropTypes.func,
  handleUpdateSequence: PropTypes.func,
  handleUpdateStatus: PropTypes.func,
  isDropDisabled: PropTypes.bool
};

export default PassengerFieldsTable;
